import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";

function MenuDialogs({
  openMenuNameDialog,
  menuName,
  confirmDeleteDialog,
  setConfirmDeleteDialog,
  deleteMenuName,
  confirmDelete,
  handleClose,
  handleInputChange,
  handleSaveMenuName,
  makeMenuDialog,
  handleMakeClose,
  handleChange,
  handleSave
}) {
  const inputRef = useRef(null);

useEffect(() => {
  if (openMenuNameDialog || makeMenuDialog) {
    inputRef.current?.focus();
  }
}, [openMenuNameDialog, makeMenuDialog]);

  return (
    <Dialog open={openMenuNameDialog || makeMenuDialog} onClose={handleClose || handleMakeClose}>
      <DialogTitle>Add Menu Name</DialogTitle>
      <DialogContent>
        <TextField
          // autoFocus
          inputRef={inputRef}
          margin="dense"
          id="menuName"
          label="Menu Name"
          type="text"
          fullWidth
          value={menuName}
          onChange={handleInputChange || handleChange} 
          helperText="Please enter a menu name to add items."
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose || handleMakeClose}>Cancel</Button>
        <Button onClick={handleSaveMenuName || handleSave}>Add</Button>
      </DialogActions>

      
      {confirmDeleteDialog && (
        <Dialog
          open={confirmDeleteDialog}
          onClose={() => setConfirmDeleteDialog(false)}
        >
          <DialogTitle>Delete Menu</DialogTitle>
          <DialogContent>
            <p>Are you sure you want to delete the menu {deleteMenuName}?</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDeleteDialog(false)}>
              Cancel
            </Button>
            <Button onClick={confirmDelete}>Delete</Button>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
}

MenuDialogs.propTypes = {
  openMenuNameDialog: PropTypes.bool.isRequired,
  menuName: PropTypes.string.isRequired,
  confirmDeleteDialog: PropTypes.bool.isRequired,
  setConfirmDeleteDialog: PropTypes.func.isRequired,
  deleteMenuName: PropTypes.string.isRequired,
  confirmDelete: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSaveMenuName: PropTypes.func.isRequired,
};

export default MenuDialogs;
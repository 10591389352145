import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from 'prop-types';

function FoodCardActions({
  quantity,
  handleIncrement,
  handleDecrement,
  handleDeleteCard,
}) {

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0.3rem",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <IconButton
        onClick={handleDeleteCard}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
          backgroundColor: "white",
          borderRadius: "50%",
          padding: "4px",
          color: "red",
          "&:hover": {
            backgroundColor: "white",
          },
        }}
      >
        <DeleteIcon />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          className="responsive_fontsize12"
          sx={{ display: "inline" }}
          component="span"
          fontWeight="600"
          color="secondary.main"
        >
          Quantity: {quantity}
        </Typography>
        <IconButton onClick={handleDecrement}>
          <RemoveIcon />
        </IconButton>
        <IconButton onClick={handleIncrement}>
          <AddIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

FoodCardActions.propTypes = {
  quantity: PropTypes.number.isRequired,
  handleIncrement: PropTypes.func.isRequired,
  handleDecrement: PropTypes.func.isRequired,
  handleDeleteCard: PropTypes.func.isRequired,
};

export default FoodCardActions;

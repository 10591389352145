import DiscountIcon from "@mui/icons-material/Discount";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import WashIcon from "@mui/icons-material/Wash";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
const aboutDataset= [
    {
        id:1,
        title:'Discounts',
        icon:DiscountIcon,
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit"
    },
    {
        id:2,
        title:'Offers',
        icon:LocalOfferIcon,
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit"
    },
    {
        id:3,
        title:'Hygiene',
        icon:WashIcon,
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit"
    },
    {
        id:4,
        title:'On Time',
        icon:AccessTimeIcon,
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit"
    },
    {
        id:5,
        title:'Quick Response',
        icon:AccessTimeIcon,
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit"
    },
    {
        id:6,
        title:'Mission',
        icon:AutoAwesomeIcon,
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit"
    },
]
export default aboutDataset;
import React from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Box,
  Avatar,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Viber from "src/assets/app.png";

function ReferDialog({ open, onClose, name, setName, email, setEmail }) {

  const handleSubmit = async (event) => {
    event.preventDefault(); 
    
    try {
      const response = await axios.post('http://localhost:8001/bbgapi/referTable', { name, email });
      console.log(response.data); // 
      onClose(); 
    } catch (error) {
      console.error(error); 
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "8px 24px 0 24px",
        }}
      >
        <DialogTitle
          className="responsive_fontsize32"
          sx={{ textAlign: "center", flex: 1 }}
        >
          Refer your friend, get a 20% discount!
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <DialogContentText
          className="responsive_fontSize25"
          sx={{ textAlign: "center", marginBottom: 2.5 }}
        >
          Refer the link with your friends and get 20% discount
        </DialogContentText>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            id="name"
            label="Name"
            type="text"
            variant="outlined"
            size="small"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ mb: 1.8 }}
          />
          <TextField
            required
            id="email"
            label="Email"
            type="email"
            variant="outlined"
            size="small"
            margin="normal"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ marginTop: "16px" }}
          >
            Share Invite Link
          </Button>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Tooltip title="Share on Facebook">
              <IconButton sx={{ color: "#1DA1F2" }}>
                <FacebookIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Share on Instagram">
              <IconButton color="secondary">
                <InstagramIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Share on WhatsApp">
              <IconButton sx={{ color: "#1DA1F2" }}>
                <WhatsAppIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Share on Viber">
              <IconButton>
                <Avatar src={Viber} sx={{ height: "24px", width: "24px" }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button className="responsive_fontsize16" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ReferDialog;
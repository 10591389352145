import React from "react";
import { Box, Button, Grid } from "@mui/material";
import esewa from "./payment_assets/esewa.png";
import khalti from "./payment_assets/khalti_logo.png";
import fonepay from "./payment_assets/fonepay.png";
import mobileBanking from "./payment_assets/mob_banking.png";

function PaymentMethodSection() {
  return (
    <Box sx={{width:"100%"}}>
      <Grid
      container 
      spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          mt: "1rem",
          mb: "1rem",
        }}
      >
        <Grid item xs={4} md={2}>
          {/* esewa */}
          <Button sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "40px",
            }}>
            <img
              src={esewa}
              alt="esewa logo payment selection"
              style={{
                height: "28px",
                width: "55px",
                objectFit:"contain"
              }}
            />
          </Button>
        </Grid>

        {/* Khalti */}
        <Grid item xs={4} md={2}>
          <Button  sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "40px",
            }}>
            <img
              src={khalti}
              alt="esewa logo payment selection"
              style={{
                height: "22px",
                width: "63px",
                objectFit:"contain"
              }}
            />
          </Button>
        </Grid>

        {/* fonepay */}
        <Grid item xs={4} md={2}>
          <Button  sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "40px",
            }}>
            <img
              src={fonepay}
              alt="fonepay logo payment selection"
              style={{
                height: "35px",
                width: "90px",
                objectFit:"contain"
              }}
            />
          </Button>
        </Grid>

        {/* mobile banking */}
        <Grid item xs={4} md={2}>
          <Button  sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "auto",
              height: "40px",
            }}>
            <img
              src={mobileBanking}
              alt="esewa logo payment selection"
              style={{
                height: "13px",
                width: "88px",
                objectFit:"contain"
              }}
            />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PaymentMethodSection;

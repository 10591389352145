import React from "react";
import {
  Box,
  Button,
  Avatar,
  TextField,
} from "@mui/material";
import packageIcon from "../../../assets/packageIcon.png";
import cookingIcon from "../../../assets/cookingIcon.png";
import deliverIcon from "../../../assets/deliverIcon.png";
import deliveredIcon from "../../../assets/deliveredIcon.png";

function SearchTrack() {
  return (
    <>
      <Box sx={{ padding: { xs: "10px", sm: "20px" } }}>
        <form style={{ position: "relative", marginBottom: "20px" }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Enter Order Id"
            className="track__order--input"
            sx={{
              marginBottom: "10px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                backgroundColor: "#f9f9f9",
                "& fieldset": {
                  borderColor: "#ccc",
                },
                "&:hover fieldset": {
                  borderColor: "#aaa",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#000",
                },
              },
              "& .MuiInputBase-input": {
                fontSize: { xs: "0.85rem", sm: "1rem" },
              },
            }}
          />
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              top: "50%",
              right: 0,
              transform: "translateY(-50%)",
              backgroundColor: "#000",
              fontSize: { xs: "0.85rem", sm: "1.25rem" },
              textTransform: "capitalize",
              borderRadius: "1.25rem",
              px: { xs: "0.95rem", sm: "1.2rem" },
              color: "#fff",
              height: "100%",
            }}
          >
            Track Order
          </Button>
        </form>
      </Box>

      <Box
        sx={{
          position: "relative",
          height: { xs: "2.5rem", sm: "3.5rem" },
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Box
          sx={{ width: "100%", height: "0.1875rem", background: "#D9D9D9" }}
        ></Box>
        <Box sx={{ position: "absolute", top: "0", left: "0" }}>
          <Avatar
            alt="Cooking Icon"
            src={cookingIcon}
            sx={{ width: { xs: 40, sm: 54 }, height: { xs: 40, sm: 54 } }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "25%",
            transform: "translateX(50%)",
          }}
        >
          <Avatar
            alt="Package Icon"
            src={packageIcon}
            sx={{ width: { xs: 40, sm: 54 }, height: { xs: 40, sm: 54 } }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "0",
            right: "25%",
            transform: "translateX(-50%)",
          }}
        >
          <Avatar
            alt="Deliver Icon"
            src={deliverIcon}
            sx={{ width: { xs: 40, sm: 54 }, height: { xs: 40, sm: 54 } }}
          />
        </Box>
        <Box sx={{ position: "absolute", top: "0", right: "0" }}>
          <Avatar
            alt="Delivered Icon"
            src={deliveredIcon}
            sx={{ width: { xs: 40, sm: 54 }, height: { xs: 40, sm: 54 } }}
          />
        </Box>
      </Box>
    </>
  )}

export default SearchTrack;
import React from "react";
import { Button } from "@mui/material";

function ButtonComponent({ onClick, sx, children, ...props }) {
  return (
    <div>
      <Button variant="contained" onClick={onClick} sx={{ ...sx }} {...props}>
        {children}
      </Button>
    </div>
  );
}

export default ButtonComponent;

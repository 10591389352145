import React, {useState, useEffect} from "react";
// import map from "../../assets/map.png";
import { Box, Typography } from "@mui/material";
// import CustomerLocation from "src/layout/map_api/customer_location";
import {
  useJsApiLoader,
  // Autocomplete,
  DirectionsRenderer,
  GoogleMap,
  Marker,
} from "@react-google-maps/api";

// const center = { lat: 27.7172, lng: 85.324 };
const LIBRARIES = ["places"];

function TrackSectionMap() {

  // Load the Google Maps API
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_BBG_MAP_API_KEY,
    libraries: LIBRARIES,
  });

  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null); // AdvancedMarkerElement instance
  const [markerPosition, setMarkerPosition] = useState(""); // Default to center
  // const originRef = useRef(null);
  // const destinationRef = useRef(null);
  // const autocompleteRef = useRef(null);
  const [directionsResponse] = useState(null);
  // const [distance, setDistance] = useState("");
  // const [duration, setDuration] = useState("");
  // const [locationText, setLocationText] = useState("");
  const [isGeolocationActive] = useState(false);

  useEffect(() => {
    if (navigator.geolocation && window.google && !isGeolocationActive) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const newPos = { lat: latitude, lng: longitude };
          setMarkerPosition(newPos);
          // setLocationText("Loading...");

          if (window.google.maps.Geocoder) {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ location: newPos }, (results, status) => {
              // if (status === "OK" && results[0]) {
              //   setLocationText(results[0].formatted_address);
              // } else {
              //   setLocationText("No results found");
              // }
            });
          }

          if (map) {
            map.panTo(newPos);
          }

          if (marker) {
            marker.setPosition(newPos);
          } else if (map) {
            const newMarker = new window.google.maps.Marker({
              position: newPos,
              map: map,
            });
            setMarker(newMarker);
          }
        },
        (err) => {
          console.error("Error: The Geolocation service failed.");
        }
      );
    }
  }, [map, marker, isGeolocationActive]);

  if (!isLoaded) {
    return (
      <Box>
        <Typography>Loading map...</Typography>
      </Box>
    );
  }

  return (
    <>
      {" "}
      {/* <img
        src={map}
        alt="Track your order"
        style={{ width: "100%", height: "100%" }}
      /> */}
      <Box sx={{
        height:"100%"
      }}>
        <Box sx={{height:{xs:"15rem", md:"25rem"}}}>
          <GoogleMap
            center={markerPosition}
            zoom={15}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            options={{
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
            }}
            onLoad={(map) => setMap(map)}
            onUnmount={() => setMap(null)}
          >
            {/* Conditionally render the marker if no directions, otherwise render the most recent directions */}
            {!directionsResponse ? (
              marker && <Marker position={markerPosition} />
            ) : (
              <DirectionsRenderer directions={directionsResponse} />
            )}
          </GoogleMap>
        </Box>
      </Box>
    </>
  );
}

export default TrackSectionMap;

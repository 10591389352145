import React from "react";
import Slider from "react-slick";
import { Box,Grid } from "@mui/material";
import FoodCard from "../FoodCard/FoodCard";

function MobileSlider({ searchedItems }) {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplaySpeed: 2000,
    autoplay: true,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 350, // Adjust for mobile screens
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <Box sx={{ display: { xs: "block", sm: "none" } }}>
      <Slider {...sliderSettings}>
        {searchedItems.map((fooditem) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={fooditem.item_id}>
            <Box sx={{ position: "relative" }}>
              <FoodCard
                fooditem={fooditem}
                subcategory={`${fooditem.item_subcategory} ${fooditem.item_name}`}
                price={fooditem.item_price}
                image={fooditem.item_image}
              />
            </Box>
          </Grid>
        ))}
      </Slider>
    </Box>
  );
}

export default MobileSlider;

import React from "react";
import { TextField, Button, InputAdornment, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const PasswordAccordion = ({
  currentPassword,
  setCurrentPassword,
  newPassword,
  setNewPassword,
  confirmNewPassword,
  setConfirmNewPassword,
  showCurrentPassword,
  setShowCurrentPassword,
  showNewPassword,
  setShowNewPassword,
  showConfirmNewPassword,
  setShowConfirmNewPassword,
  handlePasswordUpdate,
  // handlePasswordMatch,
  error,
  updateError,
}) => (
  <>
    <TextField
      fullWidth
      label="Current Password"
      type={showCurrentPassword ? "text" : "password"}
      variant="outlined"
      sx={{ mb: 2 }}
      value={currentPassword}
      onChange={(e) => setCurrentPassword(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Button onClick={() => setShowCurrentPassword(!showCurrentPassword)} aria-label="toggle password visibility">
              {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
            </Button>
          </InputAdornment>
        ),
      }}
    />
    <TextField
      fullWidth
      label="New Password"
      type={showNewPassword ? "text" : "password"}
      variant="outlined"
      sx={{ mb: 2 }}
      value={newPassword}
      onChange={(e) => setNewPassword(e.target.value)}
      error={error}
      helperText={error ? "Passwords do not match or error occurred" : ""}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Button onClick={() => setShowNewPassword(!showNewPassword)} aria-label="toggle password visibility">
              {showNewPassword ? <Visibility /> : <VisibilityOff />}
            </Button>
          </InputAdornment>
        ),
      }}
    />
    <TextField
      fullWidth
      label="Confirm New Password"
      type={showConfirmNewPassword ? "text" : "password"}
      variant="outlined"
      sx={{ mb: 2 }}
      value={confirmNewPassword}
      onChange={(e) => setConfirmNewPassword(e.target.value)}
      error={newPassword !== confirmNewPassword && confirmNewPassword !== ""}
      helperText={newPassword !== confirmNewPassword && confirmNewPassword !== "" ? "Passwords do not match" : ""}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Button onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)} aria-label="toggle password visibility">
              {showConfirmNewPassword ? <Visibility /> : <VisibilityOff />}
            </Button>
          </InputAdornment>
        ),
      }}
    />
    {updateError && <Typography color="error">{updateError}</Typography>}
    <Button variant="contained" color="primary" onClick={handlePasswordUpdate}>
      Change Password
    </Button>
  </>
);

export default PasswordAccordion;

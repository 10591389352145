import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
  TextField,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
const  url ='https://www.bbgrestaurant.com/bbgapi'

const PersonalDetailsAccordion = ({ customerId }) => {
  const [customerDetails, setCustomerDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    customer_name: '',
    customer_contact: '',
    customer_address: '',
    customer_email: '',
  });

  // Fetch customer details on component mount
  useEffect(() => {
    const fetchCustomerDetails = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('authToken');
        const customerId = localStorage.getItem('customerId');
        // console.log("Customer ID:", customerId);
        if (!token) {
          throw new Error('No token found');
        }

        // console.log("Fetching details for Customer ID:", customerId);
        const response = await axios.get(`${url}/customer/${customerId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // console.log("API Response:", response.data); // Log the whole response

        const profile = response.data.data; // Make sure to access the right part of the response
        // console.log("Profile Profile:", profile);
        if (!profile || !profile.customer_name) {
          throw new Error('Customer not found');
        }

        // Set initial customer details and form data
        setCustomerDetails(profile);
        setFormData({
          customer_name: profile.customer_name || '',
          customer_contact: profile.customer_contact || '',
          customer_address: profile.customer_address || '',
          customer_email: profile.customer_email || '',
        });
      } catch (err) {
        console.error("Error fetching customer details:", err);
        const errorMessage = err.response?.data?.message || err.message || "An unknown error occurred";
        setError('Error fetching customer details: ' + errorMessage);
      } finally {
        setLoading(false);
      }
    };
    fetchCustomerDetails();
  }, [customerId]);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = async () => {
    const customerId = localStorage.getItem('customerId');
    console.log("Customer ID:", customerId);

    const token = localStorage.getItem('authToken');
    console.log("Form Data before update:", formData); 
    try {
      const response = await axios.patch(`${url}/customer/${customerId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          // 'Content-Type': 'application/json',
        },
      });
      console.log("Response after update:", response.data);
      
      // Update customerDetails with the latest data returned from the server
      setCustomerDetails(response.data.data);
      setIsEditing(false); // Exit editing mode
    } catch (err) {
      console.error("Error updating customer details:", err);
      const errorMessage = err.response?.data?.message || err.message || "An unknown error occurred";
      setError('Error updating customer details: ' + errorMessage);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!customerDetails) {
    return <Typography>No customer details found.</Typography>;
  }

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="responsive_fontsize16">Personal Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {isEditing ? (
            <div>
              <TextField
                label="Name"
                name="customer_name"
                value={formData.customer_name}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                label="Contact"
                name="customer_contact"
                value={formData.customer_contact}
                onChange={handleChange}
                fullWidth
                style={{ marginTop: 10 }}
              />
             
              <TextField
                label="Address"
                name="customer_address"
                value={formData.customer_address}
                onChange={handleChange}
                fullWidth
                style={{ marginTop: 10 }}
              />
               <TextField
                label="Email"
                name="customer_email"
                value={formData.customer_email}
                onChange={handleChange}
                fullWidth
                style={{ marginTop: 10 }}
              />
              <Button onClick={handleSubmit} variant="contained" color="primary" style={{ marginTop: 10 }}>
                Save
              </Button>
              <Button onClick={() => setIsEditing(false)} variant="outlined" style={{ marginTop: 10, marginLeft: 10 }}>
                Cancel
              </Button>
            </div>
          ) : (
            <div>
              <Typography variant="body1">
                <strong>Name:</strong> {customerDetails.customer_name}
              </Typography>
              <Typography variant="body1">
                <strong>Contact:</strong> {customerDetails.customer_contact}
              </Typography>
              <Typography variant="body1">
                <strong>Address:</strong> {customerDetails.customer_address || "Not provided"}
              </Typography>
              <Typography variant="body1">
                <strong>Email:</strong> {customerDetails.customer_email || "Not provided"}
              </Typography>
              <Button onClick={() => setIsEditing(true)} variant="contained" color="primary" style={{ marginTop: 10 }}>
                Edit
              </Button>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default PersonalDetailsAccordion;

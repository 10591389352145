import React from "react";
import { Box, Button, Typography, Avatar } from "@mui/material";
import packageIcon from "../../assets/packageIcon.png";
import cookingIcon from "../../assets/cookingIcon.png";
import deliverIcon from "../../assets/deliverIcon.png";
import deliveredIcon from "../../assets/deliveredIcon.png";
import { useNavigate } from "react-router-dom";
function TrackSectionOrderDetails() {
  const navigate=useNavigate();
  const handleTrackClick=()=>{
    navigate("/track");
  }
  return (
    <>
      <form style={{ position: "relative" }}>
        <input
          type="text"
          placeholder="Enter Order Id: "
          className="track__order--input"
        />
        <Button
          variant="contained"
          sx={{
            position: "absolute",
            top: "50%",
            right: 0,
            transform: "translateY(-50%)",
            backgroundColor: "#000",
            fontSize: { xs: "0.85rem", sm: "1.25rem" },
            textTransform: "capitalize",
            borderRadius: "1.25rem",
            px: { xs: "0.95rem", sm: "1.2rem" },
            color: "#fff",
            height: "100%",
          }}
        >
          Track Order
        </Button>
      </form>

      <Box
        sx={{
          position: "relative",
          mt: "1.5rem",
          height: { xs: "2.5rem", sm: "3.5rem" },
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "0.1875rem",
            background: "#D9D9D9",
          }}
        ></Box>
        <Box sx={{ position: "absolute", top: "0", left: "0" }}>
          <Avatar
            alt="Cooking Icon"
            src={cookingIcon}
            sx={{ width: { xs: 40, sm: 54 }, height: { xs: 40, sm: 54 } }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "25%",
            transform: "translateX(50%)",
          }}
        >
          <Avatar
            alt="Package Icon"
            src={packageIcon}
            sx={{ width: { xs: 40, sm: 54 }, height: { xs: 40, sm: 54 } }}
          />
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: "0",
            right: "25%",
            transform: "translateX(-50%)",
          }}
        >
          <Avatar
            alt="Deliver Icon"
            src={deliverIcon}
            sx={{ width: { xs: 40, sm: 54 }, height: { xs: 40, sm: 54 } }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "0",
            right: "0",
          }}
        >
          <Avatar
            alt="Delivered Icon"
            src={deliveredIcon}
            sx={{ width: { xs: 40, sm: 54 }, height: { xs: 40, sm: 54 } }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          mt: "1.37rem",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: "1.25rem",
              fontWeight: "700",
              fontFamily: "Roboto Serif",
            }}
          >
            Order Detail
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              mt: "0.3rem",
            }}
          >
            <Typography sx={{ fontSize: "1rem", fontFamily: "Roboto Serif" }}>
              Order ID :
            </Typography>
            <Typography
              sx={{ fontSize: "0.875rem", fontFamily: "Roboto Serif" }}
            >
              2080-05-15-001x
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              mt: "0.3rem",
            }}
          >
            <Typography sx={{ fontSize: "1rem", fontFamily: "Roboto Serif" }}>
              Address :
            </Typography>
            <Typography
              sx={{ fontSize: "0.875rem", fontFamily: "Roboto Serif" }}
            >
              Sanepa-02, Lalitpur
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              mt: "0.3rem",
            }}
          >
            <Typography sx={{ fontSize: "1rem", fontFamily: "Roboto Serif" }}>
             Before :
            </Typography>
            <Typography
              sx={{ fontSize: "0.875rem", fontFamily: "Roboto Serif" }}
            >
              2:00 PM
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              mt: "0.3rem",
            }}
          >
            <Typography sx={{ fontSize: "1rem", fontFamily: "Roboto Serif" }}>
              Status:
            </Typography>
            <Typography
              sx={{ fontSize: "0.875rem", fontFamily: "Roboto Serif" }}
            >
              On The Way
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              mt: "0.3rem",
            }}
          >
            <Typography sx={{ fontSize: "1rem", fontFamily: "Roboto Serif" }}>
              Description :
            </Typography>
            <Typography
              sx={{ fontSize: "0.875rem", fontFamily: "Roboto Serif" }}
            >
              Near By EEC, 300m
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: "1rem" }}>
        <Button onClick={handleTrackClick}
          variant="contained"
          sx={{
            width: "80%",
            textTransform: "capitalize",
            backgroundColor: "#000",
            color: "#fff",
            borderRadius: "1.25rem",
          }}
        >
          {" "}
          More Detail
        </Button>
      </Box>
    </>
  );
}

export default TrackSectionOrderDetails;

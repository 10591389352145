import React from "react";
import { Box } from "@mui/material";
import CategoryButton from "./categoryButton";

const CategorySection = ({ categories, selectedCategory, setSelectedCategory }) => {
  return (
    <Box sx={{ ml: { xs: 0, md: 20 } }}>
      <CategoryButton
        categories={categories}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
    </Box>
  );
};

export default CategorySection;

import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import OrderDetails from "./orderDetail";
import SearchBar from "../../../components/search_bar/search_bar_main";
import SocialMediaBar from "src/components/social_media_bar/social_media_bar_main";
import dataList from "./dataListTrack";
import SearchTrack from "./searchTrack.jsx";

function TrackLandingPage() {
  // Split the data list into two parts by alternating items
  const firstHalf = dataList.filter((_, index) => index % 2 === 0);
  const secondHalf = dataList.filter((_, index) => index % 2 !== 0);

  return (
    <>
    <Box sx={{
      mt:12
    }}>
      <SearchBar />
      <SocialMediaBar />
      <Box sx={{
          padding: "0 10px 15px 10px" }}>
        <SearchTrack />
      </Box>
      <Box>
        <Typography
          className="responsive_fontsize32"
          sx={{ textAlign: "center", marginBottom: "20px" }}
        >
          Order Details
        </Typography>
        <Box>
          <Grid container spacing={1.2} sx={{ 
             }}>
            {/* Render the first half of the data */}
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
               
              }}
            >
              {firstHalf.map((item) => (
                <Box 
                  key={item.id}
                  sx={{
                    width: "100%", 
                    maxWidth: "400px", 
                    paddingLeft:"10px", 
                  }}
                >
                  <OrderDetails content={item} />
                </Box>
              ))}
            </Grid>
            {/* Render the second half of the data */}
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {secondHalf.map((item) => (
                <Box
                  key={item.id}
                  sx={{
                    width: "100%", 
                    maxWidth: "400px", 
                    paddingLeft:"10px",                  
                  }}
                >
                  <OrderDetails content={item} />
                </Box>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Box>
      </Box>
    </>
  );
}

export default TrackLandingPage;

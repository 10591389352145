const foodItems = [
  {
    id: 1,
    image: "https://img.freepik.com/premium-photo/luxury-wine-bottle-pouring-elegance-celebration-black-background-generated-by-ai_188544-208363.jpg",
    subcategory: ["Luxury Wine"],
    detail: "250ml Gold wine from United Kingdom, 12 Years old sweet and smooth.",
    price: ["Rs.", "199"],
    eventStartDate: "2024-09-02",
    eventEndDate: "2024-09-10",
    eventStartTime: "7:00 AM",
    eventEndTime: "10:00 PM",
    occasion: "Dashain Special",
    category: "Beverages",
    classification: [
      {
        classified: "veg",
        subcategory: [""],
      },
    ],
    ingredients: ["Grapes", "Yeast", "Oak", "Sugar"]
  },
  {
    id: 2,
    image: "https://img.freepik.com/premium-photo/bowl-chicken-curry-with-red-chilies-black-background_923143-549.jpg",
    subcategory: ["Chilli Curry"],
    detail: "Spicy curry with a blend of chilies and spices.",
    price: ["Rs.", "199"],
    eventStartDate: "2024-10-01",
    eventEndDate: "2024-10-10",
    eventStartTime: "7:00 AM",
    eventEndTime: "10:00 PM",
    occasion: "Dashain Special",
    category: "Curry",
    classification: [
      {
        classified: "veg",
        subcategory: ["curry"],
      },
    ],
    ingredients: ["Chicken", "Onions", "Tomatoes", "Chili Powder", "Garlic", "Ginger", "Spices"]
  },
  {
    id: 3,
    image: "https://png.pngtree.com/thumb_back/fh260/background/20231011/pngtree-chicken-mandi-biryani-presented-in-an-earthenware-dish-against-a-stylish-image_13596551.png",
    subcategory: ["Chicken Biryani", "Mutton Biryani", "Veg Biryani"],
    detail: "Aromatic basmati rice with tender chicken pieces and spices.",
    price: ["Rs.", "199"],
    eventStartDate: "2024-08-02",
    eventEndDate: "2024-08-10",
    eventStartTime: "7:00 AM",
    eventEndTime: "10:00 PM",
    occasion: "Dashain Special",
    category: "Biryani",
    classification: [
      {
        classified: "veg",
        subcategory: ["mushroom", "cheese", "veg"],
      },
      {
        classified: "non-veg",
        subcategory: ["chicken", "Mutton"],
      },
      {
        classified: "platter", // Added platter classification
        subcategory: [""],
      },
    ],
    ingredients: ["Basmati Rice", "Chicken", "Mutton", "Onions", "Tomatoes", "Yogurt", "Spices", "Herbs"]
  },
  {
    id: 4,
    image: "https://img.freepik.com/free-photo/front-view-burger-stand_141793-15542.jpg",
    subcategory: ["Burger Cheese", "Veg Burger", "Chicken Burger"],
    detail: "Juicy burger with melted cheese and fresh vegetables.",
    price: ["Rs.", "199"],
    eventStartDate: "2024-09-07",
    eventEndDate: "2024-09-18",
    eventStartTime: "7:00 AM",
    eventEndTime: "10:00 PM",
    occasion: "Dashain Special",
    category: "Burger",
    classification: [
      {
        classified: "veg",
        subcategory: ["mushroom", "cheese", "veg"],
      },
      {
        classified: "non-veg",
        subcategory: ["chicken"],
      },
    ],
    ingredients: ["Burger Bun", "Cheese", "Lettuce", "Tomato", "Onion", "Pickles", "Burger Patty (Chicken/Vegetable)"]
  },
  {
    id: 5,
    image: "https://img.freepik.com/premium-photo/delicious-nepali-momo-dumplings-plate-black-background-illustration-generative-ai_850810-1425.jpg",
    subcategory: ["Veg Mo-Mo", "Chicken Momo", "Buff Momo"],
    detail: "Juicy momo with different fillings.",
    price: ["Rs.", "199"],
    eventStartDate: "2024-09-08",
    eventEndDate: "2024-09-15",
    eventStartTime: "7:00 AM",
    eventEndTime: "10:00 PM",
    occasion: "Dashain Special",
    category: "Momo",
    classification: [
      {
        classified: "veg",
        subcategory: ["mushroom", "paneer", "veg"],
      },
      {
        classified: "non-veg",
        subcategory: ["chicken"],
      },
    ],
    ingredients: ["Momo Dough", "Chicken/Paneer", "Vegetables", "Spices", "Ginger", "Garlic"]
  },
  {
    id: 6,
    image: "https://i.pinimg.com/736x/08/4d/0a/084d0a9db7e05974aeeb2d018866196c.jpg",
    subcategory: ["Veg Chowmein", "Chicken Chowmein", "Buff Chowmein"],
    detail: "Juicy chowmein with different fillings.",
    price: ["Rs.", "199"],
    eventStartDate: "2024-10-10",
    eventEndDate: "2024-10-15",
    eventStartTime: "7:00 AM",
    eventEndTime: "10:00 PM",
    occasion: "Dashain Special",
    category: "Chowmein",
    classification: [
      {
        classified: "veg",
        subcategory: ["mushroom", "Egg", "veg"],
      },
      {
        classified: "non-veg",
        subcategory: ["chicken"],
      },
    ],
    ingredients: ["Chowmein Noodles", "Chicken/Buff", "Vegetables", "Soy Sauce", "Garlic", "Ginger"]
  },
  {
    id: 7,
    image: "https://static.vecteezy.com/system/resources/thumbnails/023/007/593/small_2x/pizza-veggie-italian-pizza-with-mozzarella-olives-sausage-and-vegetables-on-black-background-ai-generated-photo.jpg",
    subcategory: ["Cheese Pizza", "Chicken Pizza", "Veg Pizza"],
    detail: "Delicious pizza with cheese and fresh vegetables.",
    price: ["Rs.", "199"],
    eventStartDate: "2024-09-02",
    eventEndDate: "2024-09-10",
    eventStartTime: "7:00 AM",
    eventEndTime: "10:00 PM",
    occasion: "Eid Special",
    category: "Pizza",
    classification: [
      {
        classified: "veg",
        subcategory: ["mushroom", "cheese", "veg"],
      },
      {
        classified: "non-veg",
        subcategory: ["chicken"],
      },
    ],
    ingredients: ["Pizza Dough", "Mozzarella Cheese", "Tomato Sauce", "Vegetables", "Chicken/Sausage", "Spices"]
  },
  {
    id: 8,
    image: "https://img.pikbest.com/wp/202344/tempting-delicious-chicken-tikka-a-culinary-delight-against-textured-gray-backdrop_9926453.jpg!sw800",
    subcategory: ["Chicken Tikka"],
    detail: "Juicy chicken tikka with a blend of spices.",
    price: ["Rs.", "199"],
    eventStartDate: "2024-10-20",
    eventEndDate: "2024-10-25",
    eventStartTime: "7:00 AM",
    eventEndTime: "10:00 PM",
    occasion: "Eid Special",
    category: "Main Course",
    classification: [
      {
        classified: "veg",
        subcategory: ["mushroom"],
      },
      {
        classified: "non-veg",
        subcategory: ["chicken"],
      },
    ],
    ingredients: ["Chicken", "Yogurt", "Spices", "Garlic", "Ginger", "Lemon Juice"]
  },
];

export default foodItems;

import React from "react";
import { Box } from "@mui/material";
import AboutDescription from "./aboutDescription";
function AboutLandingPage() {
  return (
    <>
      <Box
        sx={{
          mt: 13,
        }}
      >
        <AboutDescription />
      </Box>
    </>
  );
}
export default AboutLandingPage;

import React, { useState } from "react";
import { TextField, Button, DialogContent } from "@mui/material";

const OTPSection = ({ onVerifyOTP }) => {
  const [otp, setOtp] = useState("");

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleVerify = () => {
    onVerifyOTP();
  };

  return (
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        label="Enter OTP"
        type="text"
        fullWidth
        variant="standard"
        value={otp}
        onChange={handleOtpChange}
      />
      <Button onClick={handleVerify} variant="contained" color="primary" sx={{ mt: 2 }}>
        Verify OTP
      </Button>
    </DialogContent>
  );
};

export default OTPSection;

import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import { LocalActivity as LocalActivityIcon, ExpandMore as ExpandMoreIcon} from "@mui/icons-material";

const SubscriptionAccordion = () => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="subscription-content" id="subscription-header">
      <LocalActivityIcon sx={{ mr: 1 }} />
      <Typography>Subscription</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography variant="body1">Manage your subscription preferences here.</Typography>
    </AccordionDetails>
  </Accordion>
);

export default SubscriptionAccordion;

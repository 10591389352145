import React from "react";
import { Button } from "@mui/material";

function CustomButton({ text, onClick }) {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        width: { xs: "80px", md: "100px" },
        fontSize: { xs: "12px", md: "14px" },
        textTransform: "capitalize",
        borderRadius: "5px",
        color: "#000000",
        backgroundColor: "#EAA017",
        "&:hover": {
          backgroundColor: "#8C5A00",
          color: "#FFFFFF",
        },
        padding: { xs: "6px 10px", md: "8px 14px" },
      }}
    >
      {text}
    </Button>
  );
}

export default CustomButton;

import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Fade,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";
import PasswordAccordion from "./passwordAccordion";
import PersonalDetailsAccordion from "./personalDetailsAccordion";
import AdsAccordion from "./adsAccordion";
import ReferAccordion from "./referAccordion";
import NotificationAccordion from "./notificationAccordion";
import ReviewsAccordion from "./reviewsAccordion";
import SubscriptionAccordion from "./subscriptionAccordion";

const  url ='https://www.bbgrestaurant.com/bbgapi'

function SettingPage() {
  // const [error] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [updateMessage, setUpdateMessage] = useState("");
  const [updateError, setUpdateError] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [nestedExpanded, setNestedExpanded] = useState(false);

  const handlePasswordMatch = () => newPassword === confirmNewPassword;

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleNestedAccordionChange = (panel) => (event, isExpanded) => {
    setNestedExpanded(isExpanded ? panel : false);
  };

  const handlePasswordUpdate = async () => {
    const customerId = localStorage.getItem("customerId");
    // console.log("Updating password for Customer ID:", customerId); // Log Customer ID
    // console.log("Current Password:", currentPassword);
    // console.log("New Password:", newPassword);
    // Validate input
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      setUpdateError("Please fill all fields");
      return;
    }
    if (!handlePasswordMatch()) {
      setUpdateError("New password and confirmation do not match");
      return;
    }
    try {
      const response = await axios.patch(
        `${url}/customer/password/${customerId}`,
        {
          current_password: currentPassword,
          new_password: newPassword, // Ensure the new password is sent here
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      console.log(localStorage.getItem("authToken"));
      const data = response.data;
      if (response.status === 200) {
        setUpdateMessage("Password updated successfully");
        toast.success("Password changed successfully.");
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        setUpdateError("");
      } else {
        setUpdateError(data.message || "Failed to update password");
      }     
    } catch (error) {
      console.log(error);
      setUpdateError("Server error");
    }
  };
  

  return (
    <>
      <Accordion
        expanded={expanded === "accountsCenter"}
        onChange={handleAccordionChange("accountsCenter")}
        TransitionComponent={Fade}
        transitionDuration={400}
        sx={{
          "& .MuiAccordion-region": {
            height: expanded === "accountsCenter" ? "auto" : 0,
          },
          "& .MuiAccordionDetails-root": {
            display: expanded === "accountsCenter" ? "block" : "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <AccountCircleIcon sx={{ mr: 1 }} />
          <Typography className="responsive_fontsize18">
            Accounts Center
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          {/* Password Nested Accordion */}
          <Accordion
            expanded={nestedExpanded === "password"}
            onChange={handleNestedAccordionChange("password")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="password-content"
              id="password-header"
            >
              <Typography className="responsive_fontsize16">
                Password
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PasswordAccordion
                currentPassword={currentPassword}
                setCurrentPassword={setCurrentPassword}
                newPassword={newPassword}
                setNewPassword={setNewPassword}
                confirmNewPassword={confirmNewPassword}
                setConfirmNewPassword={setConfirmNewPassword}
                showCurrentPassword={showCurrentPassword}
                setShowCurrentPassword={setShowCurrentPassword}
                showNewPassword={showNewPassword}
                setShowNewPassword={setShowNewPassword}
                showConfirmNewPassword={showConfirmNewPassword}
                setShowConfirmNewPassword={setShowConfirmNewPassword}
                handlePasswordUpdate={handlePasswordUpdate}
                updateError={updateError}
                setUpdateError={setUpdateError}
                updateMessage={updateMessage}
                setUpdateMessage={setUpdateMessage}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <PersonalDetailsAccordion/>
          </Accordion>

          <Accordion>
            <AdsAccordion/>
          </Accordion>
        </AccordionDetails>
      </Accordion>

      <ReferAccordion/>
      <NotificationAccordion/>
      <ReviewsAccordion/>
      <SubscriptionAccordion/>
    </>
  );
}

export default SettingPage;

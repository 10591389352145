import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import {
  Star as StarIcon,
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";
import {
  FoodBank as ChickenIcon,
  EmojiFoodBeverage as OnionIcon,
  Fastfood as RiceIcon,
  Fastfood as YogurtIcon,
} from "@mui/icons-material";
import RelatedCardSlider from "./relatedCardSlider";
import feedbackData from "./feedbackData";
import PropTypes from "prop-types";

const ingredientIcons = {
  "Basmati Rice": <RiceIcon />,
  veg: <ChickenIcon />,
  Onions: <OnionIcon />,
  Yogurt: <YogurtIcon />,
  // Add other mappings as needed
};

function IngredientsAndFeedback({
  stars = [],
  itemIngredients = [],
  currentFoodItem,
}) {
  return (
    <>
      <Box sx={{ paddingBottom: "10px", backgroundColor: "#eef0eb" }}>
        <Typography
          className="responsive_fontsize32"
          sx={{
            textAlign: "center",
            mt: { xs: 3, md: 4 },
            mb: { xs: 1, md: 2 },
          }}
        >
          Ingredients
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{ justifyContent: "center", mt: { xs: 1, md: 3 } }}
        >
          {itemIngredients.map((ingredient, index) => (
            <Grid
              item
              xs={6}
              sm={3}
              key={index}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {ingredientIcons[ingredient] || null}{" "}
              {/* Render icon if available */}
              <Typography sx={{ ml: 1 }}>{ingredient}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box
        sx={{
          backgroundColor: "#f3f5f0",
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          padding: "0 10px",
        }}
      >
        <Typography
          sx={{ marginBottom: "20px" }}
          className="responsive_fontsize32"
        >
          Related Foods
        </Typography>

        {/* Pass the currentFoodItem to the RelatedCardSlider */}
        <RelatedCardSlider currentFoodItem={currentFoodItem} />
      </Box>

      <Grid
        container
        sx={{
          backgroundColor: "#eef0eb",
          mt: { xs: 1.2, md: 3 },
        }}
      >
        <Grid
          item
          xs={12}
          sm={5.4}
          md={5.4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ padding: "30px" }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Typography className="responsive_fontsize20" sx={{ mr: 1 }}>
                4.9
              </Typography>
              {stars.map((_, index) => (
                <StarIcon key={index} sx={{ color: "#EAA017" }} />
              ))}
              <Typography className="responsive_fontsize16" sx={{ ml: 1 }}>
                245 Views
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <ThumbUpIcon sx={{ cursor: "pointer", mr: 0.5 }} />
              <ThumbDownIcon sx={{ cursor: "pointer" }} />
            </Box>
            <Box>
              {feedbackData.map((feedback) => (
                <Box
                  key={feedback.id}
                  sx={{ display: "flex", alignItems: "center", mb: 2 }}
                >
                  <AccountCircleIcon sx={{ fontSize: 40, mr: 1 }} />
                  <Box>
                    <Typography
                      className="responsive_fontsize18"
                      sx={{ color: "#5a595c", fontWeight: "bold" }}
                    >
                      {feedback.name}
                    </Typography>
                    <Typography
                      className="responsive_fontsize18"
                      sx={{ mt: 0.5 }}
                    >
                      {feedback.comment}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

IngredientsAndFeedback.propTypes = {
  stars: PropTypes.array,
  itemIngredients: PropTypes.array,
  currentFoodItem: PropTypes.object.isRequired,
};

export default IngredientsAndFeedback;

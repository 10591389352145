import React from "react";
import { Helmet } from "react-helmet-async";
import SearchBar from "../components/search_bar/search_bar_main";
import SocialMediaBar from "../components/social_media_bar/social_media_bar_main";
import MenuView from "src/views/menu";


function MenuPage() {
  return (
    <>
     <SearchBar />
      <SocialMediaBar />
      <Helmet>
        {/* <title>Menu</title> */}
        <meta
          name="description"
          content="Welcome to our restaurant. Explore our menu and order online"
        />
      </Helmet>
      <MenuView />
    </>
  );
}

export default MenuPage;

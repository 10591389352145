// src/components/ProfileLoad.jsx
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchCustomerProfile, selectCustomerProfile, selectCustomerLoading } from 'src/redux/slices/customerSlice';

const ProfileLoad = () => {
  const { customerId } = useParams();
  const dispatch = useDispatch();
  const customerProfile = useSelector(selectCustomerProfile);
  const loading = useSelector(selectCustomerLoading);

  useEffect(() => {
    if (customerId) {
      dispatch(fetchCustomerProfile(customerId));
    }
  }, [customerId, dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!customerProfile) {
    return <div>No Profile Found</div>;
  }

  return (
    <div>
      <h1>Customer Profile</h1>
      <img src={customerProfile.customer_profile} alt="Customer Profile" />
      {/* Render other profile details here */}
    </div>
  );
};

export default ProfileLoad;

import React, { useState, useEffect,useMemo } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import SearchBar from "src/components/search_bar/search_bar_main";
import SocialMediaBar from "src/components/social_media_bar/social_media_bar_main";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ItemList from "./ItemListing";
import PaginationControls from "./PaginationControl";

function NonVegItems() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const joinData = useSelector((state) => state.menus.joinData);  // Fetch data from Redux store

  const itemsPerPage = 3;
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplaySpeed: 2000,
    autoplay: true,
    slidesToScroll: 1,
    arrows: false,
  };

  const [displayedItems, setDisplayedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  // Memoize filtered non-veg items
  const filteredNonVegItems = useMemo(() => {
    if (joinData?.length > 0) {
      return (joinData[0] || []).filter((item) => {
        const ingredients = JSON.parse(item.item_ingredients || "[]").map((ing) => ing.toLowerCase());
        const nonVegIngredients = ["chicken", "mutton", "buff", "fish"];
        const isNonVeg = ingredients.some((ingredient) => nonVegIngredients.includes(ingredient));
        const isWine = item.item_name.toLowerCase() === "wine";
        return isNonVeg && !isWine;
      });
    }
    return [];
  }, [joinData]);

  useEffect(() => {
    if (filteredNonVegItems.length > 0) {
      setDisplayedItems(filteredNonVegItems.slice(0, itemsPerPage));
    }
  }, [filteredNonVegItems, itemsPerPage]);

  const handleMoreClick = () => {
    const newPage = currentPage + 1;
    const startIndex = newPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    setDisplayedItems(filteredNonVegItems.slice(0, endIndex));
    setCurrentPage(newPage);
  };

  const handleLessClick = () => {
    const newPage = currentPage - 1;
    if (newPage >= 0) {
      const startIndex = newPage * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setDisplayedItems(filteredNonVegItems.slice(0, endIndex));
      setCurrentPage(newPage);
    }
  };

  return (
    <>
      <SearchBar />
      <SocialMediaBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
          m: {
            xs: "7rem 20px",
            sm: "7rem 25px",
            md: "7rem 30px",
            lg: "7rem 35px",
          },
          px: { xs: 2, sm: 2, md: 5, lg: 10 },
        }}
      >
        <Typography
          className="responsive_fontsize24"
          sx={{
            fontWeight: 700,
            fontFamily: "roboto serif",
            textTransform: "uppercase",
          }}
        >
          Non-Veg Items
        </Typography>
        <ItemList
          displayedItems={displayedItems}
          isMobile={isMobile}
          sliderSettings={sliderSettings}
        />
        {!isMobile && (
          <PaginationControls
            filteredItems={filteredNonVegItems}
            displayedItems={displayedItems}
            itemsPerPage={itemsPerPage}
            handleMoreClick={handleMoreClick}
            handleLessClick={handleLessClick}
          />
        )}
      </Box>
    </>
  );
}

export default NonVegItems;

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";


function DeleteMenuPopover({
  // deletedMenu,
  openDelete,
  confirmDelete,
  deleteMenuName,
  closeDelete,
  handleOpenDeleteDialog,
  handleMenuDeleteConfirm,
  handleDeleteDialogCancel

}) {
  return (
    <Dialog open={handleOpenDeleteDialog || openDelete} onClose={handleDeleteDialogCancel || closeDelete}>
      <DialogTitle>Delete Menu</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete the menu "{deleteMenuName}"?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeleteDialogCancel || closeDelete }>Cancel</Button>
        <Button onClick={handleMenuDeleteConfirm || confirmDelete} color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteMenuPopover;

import { Helmet } from "react-helmet-async";
import NotFoundView from "src/views/not_found";

function NotFoundPage() {
  return (
    <>
      <Helmet>
        <title>Page Not Found | BBG</title>
        <meta name="description" content="Page not found." />
      </Helmet>

      <NotFoundView />
    </>
  );
}

export default NotFoundPage;

// notification_list.js

const notificationListData = [
    {
        id: '1',
        notificationImage : 'https://img.freepik.com/premium-photo/luxury-wine-bottle-pouring-elegance-celebration-black-background-generated-by-ai_188544-208363.jpg',
        notificationText : 'Hi [Customer Name], greetings from [Your Restaurant Name]! This is to inform you that you reservation for [Number of customers] on [Date] at [Time] is confirmed!',
        notificationDate: new Date()
    },
    {
        id: '2',
        notificationImage : 'https://img.freepik.com/premium-photo/luxury-wine-bottle-pouring-elegance-celebration-black-background-generated-by-ai_188544-208363.jpg',
        notificationText : 'Friendly reminder: Your reservation at [Restaurant Name] is tomorrow at [Time]. We’re excited to serve you. If your plans change, please let us know. See you soon!',
        notificationDate: new Date()
    },
    {
        id: '3',
        notificationImage : 'https://img.freepik.com/premium-photo/luxury-wine-bottle-pouring-elegance-celebration-black-background-generated-by-ai_188544-208363.jpg',
        notificationText : 'Hi [Customer Name], this is to update you that your table for [Number of customers] is ready. Looking forward to hosting you soon.',
        notificationDate: new Date()
    },
    {
        id: '4',
        notificationImage : 'https://img.freepik.com/premium-photo/luxury-wine-bottle-pouring-elegance-celebration-black-background-generated-by-ai_188544-208363.jpg',
        notificationText : 'Welcome to [Restaurant Name]’s exclusive text messaging updates! Get ready for mouthwatering offers, event invites, and more. Stay tuned for exciting news!',
        notificationDate: new Date()
    },
    {
        id: '5',
        notificationImage : 'https://img.freepik.com/premium-photo/luxury-wine-bottle-pouring-elegance-celebration-black-background-generated-by-ai_188544-208363.jpg',
        notificationText : 'We hope you had a wonderful dining experience at [Restaurant Name]! Your satisfaction is our priority. Please share your feedback, and we can’t wait to welcome you back soon.',
        notificationDate: new Date()
    }
];

export default notificationListData;

import React from "react";
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

function MenusList({ customerMadeMenus }) {
    if (!customerMadeMenus || customerMadeMenus.length === 0) {
        return (
            <Box sx={{ mt: 2, textAlign: "center" }}>
                <Typography variant="body1">No menus found.</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ textAlign: "center", mb: 2 }}>
                Customer Made Menus
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className="responsive_fontsize16" align="center" sx={{fontWeight:"bold"}}>S.N</TableCell>
                            <TableCell className="responsive_fontsize16"  align="center" sx={{fontWeight:"bold"}}>Menu Name</TableCell>
                            <TableCell className="responsive_fontsize16"  align="center" sx={{fontWeight:"bold"}}>Items</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {customerMadeMenus.map((menu, index) => (
                            <TableRow key={menu.menu_id}>
                                <TableCell align="center">{index + 1}</TableCell>
                                <TableCell align="center">{menu.menu_name || "N/A"}</TableCell>
                                 <TableCell align="center">
                                    {menu.menu_items && Array.isArray(menu.menu_items) 
                                        ? menu.menu_items.map(itemObj => Object.values(itemObj)).flat().join(", ") 
                                        : "N/A"}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default MenusList;

import { CONFIRM_ORDER, CLEAR_CART } from "../actions/cartAction";

const initialState = {
  cart: [],
  orders: [],
};

const confirmationOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFIRM_ORDER:
      return {
        ...state,
        orders: [...state.orders, ...state.cart],
        cart: [],
      };
    case CLEAR_CART:
      return {
        ...state,
        cart: [],
      };
    // other cases
    default:
      return state;
  }
};

export default confirmationOrderReducer;

const menuItems = [
  {
    id: 1,
    image: "https://img.freepik.com/premium-photo/luxury-wine-bottle-pouring-elegance-celebration-black-background-generated-by-ai_188544-208363.jpg",
    subcategory: ["Luxury Wine"],
    price: ["Rs.", "199"],
    classification: [{ classified: "platter", subcategory: ["chicken"] }],
  },
  
    {
        id: 2,
        image:"https://img.freepik.com/premium-photo/fried-rice-bowl-dark-background_666745-575.jpg",        subcategory: ["Chicken Dish"],
        price: ["Rs.", "199"],
        classification: [
          { classified: "non-veg", subcategory: ["chicken"] },
          { classified: "veg", subcategory: ["non veg"] }
        ],
  },
  {
    id: 3,
    image: "https://png.pngtree.com/thumb_back/fh260/background/20231011/pngtree-chicken-mandi-biryani-presented-in-an-earthenware-dish-against-a-stylish-image_13596551.png",
    subcategory: ["Chicken Biryani", "mutton Biryani", "veg Biryani"],
    price: ["Rs.", "199"],
    classification: [
    
      { classified: "non-veg", subcategory: ["chicken", "mutton"] },
      { classified: "platter", subcategory: ["chicken"] },
    ],
  },
  {
    id: 4,
    image: "https://img.freepik.com/free-photo/front-view-burger-stand_141793-15542.jpg",
    subcategory: ["Burger Cheese", "veg Burger", "chicken Burger"],
    price: ["Rs.", "199"],
    classification: [
      { classified: "Akabare sukuti", subcategory: ["mushroom", "cheese", "veg"] },
      { classified: "non-veg", subcategory: ["chicken"] },
    ],
  },
  {
    id: 5,
    image: "https://img.freepik.com/premium-photo/delicious-nepali-momo-dumplings-plate-black-background-illustration-generative-ai_850810-1425.jpg",
    subcategory: ["Veg Mo-Mo", "chicken Momo", "Buff Momo"],
    price: ["Rs.", "199"],
    classification: [
      { classified: "momo", subcategory: ["chicken", "paneer", "veg"] },
      { classified: "non-veg", subcategory: ["chicken"] },
    ],
  },
  {
    id: 6,
    image: "https://i.pinimg.com/736x/08/4d/0a/084d0a9db7e05974aeeb2d018866196c.jpg",
    subcategory: ["Veg Chowmein", "chicken Chowmein", "Buff Chowmein"],
    price: ["Rs.", "199"],
    classification: [
      { classified: "veg", subcategory: ["nonveg", "Egg", "chicken"] },
      { classified: "non-veg", subcategory: ["chicken"] },
    ],
  },
  {
    id: 7,
    image: "https://static.vecteezy.com/system/resources/thumbnails/023/007/593/small_2x/pizza-veggie-italian-pizza-with-mozzarella-olives-sausage-and-vegetables-on-black-background-ai-generated-photo.jpg",
    subcategory: ["Cheese Pizza", "chicken pizza", "veg pizza"],
    price: ["Rs.", "199"],
    classification: [
      { classified: "veg", subcategory: ["mushroom", "cheese", "veg"] },
      { classified: "non-veg", subcategory: ["chicken"] },
    ],
  },
  {
    id: 8,
    image: "https://img.pikbest.com/wp/202344/tempting-delicious-chicken-tikka-a-culinary-delight-against-textured-gray-backdrop_9926453.jpg!sw800",
    subcategory: [" Tikka"],
    price: ["Rs.", "199"],
    classification: [
      { classified: "non-veg", subcategory: ["chicken", "veg", "cheese"] },
      { classified: "akabare-sukuti", subcategory: ["buff sukuti", "platter", "veg"] },
      
    ],
  },
  {
    id: 9,
   image:"https://wilson.com.np/wp-content/uploads/2021/06/Thakali-Khana-Set-taste-of-nepal-foods.png",    subcategory: ["Chicken Tikka"],
    price: ["Rs.", "199"],
    
    classification: [
       { 
        classified: "akabare-sukuti", subcategory: ["buff sukuti", "platter", "veg"] },
        { classified: "platter", subcategory: ["chicken"] },
       
    ],
  },
  {
    id: 7,
    image:"https://www.shutterstock.com/image-photo/vegetarian-roll-vegetables-tofu-on-600nw-1429996589.jpg",
    subcategory: ["Kati-Roll"],
    price: ["Rs.", "199"],
    classification: [
      { classified: "veg", subcategory: ["veg"] },
      { classified: "non-veg", subcategory: ["chicken"] },
    ],
  },
];

export default menuItems;

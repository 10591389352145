import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { keyframes } from "@emotion/react";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
function DiscountOfferSection({ content }) {
  const [hover, setHover] = useState(false);
  const IconComponent = content.icon;

  return (
    <>
      {/* <Grid> */}
      <Box
        sx={{
          // backgroundColor:"red",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingTop: "20px",
          // height: "auto",
        }}
      >
        <Box
          sx={{
            // backgroundColor: "blue",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // height: "auto",
            padding: "20px",
            width: { xs: "80%", sm: "200px" },
            margin: "0px auto",
            position: "relative",
            borderRadius: "12px",
            height: hover ? "auto" : { xs: "80px", sm: "100px" },
            transition:
              "background-color 3s ease-in-out, color 3s ease-in-out, height 0.5s ease",
            "&:hover": {
              backgroundColor: "#166F2A",
              color: "#ffffff",
            },
            zIndex: 1,
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onTouchStart={() => setHover(true)}
        >
          {/* Small box */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: "-25px",
              border: "2px solid #ffffff",
              width: "50px",
              height: "50px",
              backgroundColor: "#166F2A",
              position: "absolute",
              borderRadius: "8px",
            }}
          >
            {IconComponent && <IconComponent sx={{ color: "#ffffff" }} />}
          </Box>

          {/* Title */}
          <Typography
            className="responsive_fontsize20"
            sx={{
              marginTop: "15px",
            }}
          >
            {content.title}
          </Typography>

          {/* Divider Box */}
          <Box
            sx={{
              marginTop: "10px",
              height: "2px",
              width: "50px",
              borderRadius: "1px",
              backgroundColor: "#166F2A",
            }}
          ></Box>
          {hover && (
            <Typography
              className="responsive_fontsize16"
              sx={{
                color: "#ffffff",
                marginTop: "10px",
                textAlign: "center",
                opacity: hover ? 1 : 0,
                transition: hover
                  ? "opacity 1s 0.5s, transform 1s 0.5s"
                  : "opacity 0.5s, transform 0.5s",
                transform: hover ? "translateY(0)" : "translateY(10px)",
                animation: `${fadeIn} 0.3s`,
              }}
            >
              {content.description}
            </Typography>
          )}
        </Box>
      </Box>
      {/* </Grid> */}
    </>
  );
}
export default DiscountOfferSection;

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Container,
} from "@mui/material";
import TrackSection from "../../section/section_track/track_section_main";
import MobileDownloadMainSection from "../../section/section_mobile_download/mobile_download_section_main";
import CloseIcon from "@mui/icons-material/Close";
import PromiseSection from "../../section/section_promise/promise_section_main";
import SearchBar from "../../components/search_bar/search_bar_main";
import SocialMediaBar from "../../components/social_media_bar/social_media_bar_main";
import HomeFirstSection from "./components/home_first_section";
import HomeSecondSection from "./components/home_second_section";
import HomeThirdSection from "./components/home_third_section";
import ExtraFoodSlider from "../../components/offerSection/extraFood/sliderFood";
import "./home.css";

function HomeView() {
  const [openExtraFoodOffer, setOpenExtraFoodOffer] = useState(false);
  // const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    setOpenExtraFoodOffer(true);
    // setOpenAlert(true);
  }, []);

  const handleCloseExtraFoodOffer = () => {
    setOpenExtraFoodOffer(false);
  };

  // const handleCloseAlert = () => {
  //   setOpenAlert(false);
  // };

  return (
    <main>
      <SearchBar />
      <SocialMediaBar />
      <Box component="section" sx={{pb:"20px", background: "rgba(0, 0, 0, 0.01)",}} >
        <HomeFirstSection />
      </Box>
      <Box component="section" sx={{ background: "#fff" }}>
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: { xs: 2, sm: 4, lg: 8 },
          }}
        >
          <HomeSecondSection />
        </Container>
      </Box>
      <Box component="section" sx={{ backgroundColor: "#FCF8F8" }}>
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: { xs: 2, sm: 4, lg: 8 },
          }}
        >
          <HomeThirdSection />
        </Container>
      </Box>
      <Box component="section" sx={{ backgroundColor: "#FCFCFC" }}>
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: { xs: 2, sm: 4, lg: 8 },
          }}
        >
          <TrackSection />
        </Container>
      </Box>
      <Box component="section" sx={{ backgroundColor: "#FCF8F8" }}>
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: { xs: 2, sm: 4, lg: 8 },
          }}
        >
          <PromiseSection />
        </Container>
      </Box>
      <Box component="section" sx={{ backgroundColor: "#fff" }}>
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: { xs: 2, sm: 4, lg: 8 },
          }}
        >
          <MobileDownloadMainSection />
        </Container>
      </Box>

      <Dialog
        open={openExtraFoodOffer}
        onClose={handleCloseExtraFoodOffer}
        fullWidth
        maxWidth="md"
        BackdropProps={{ onClick: handleCloseExtraFoodOffer }}
      >
        <DialogTitle>
          <Typography
            className="responsive_fontsize32"
            sx={{ textAlign: "center", color: "#045307" }}
          >
            Special Offers
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseExtraFoodOffer}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ExtraFoodSlider />
        </DialogContent>
      </Dialog>

    </main>
  );
}

export default HomeView;

const reviewsData = [
    {
        id: 1,
        userName: 'John Doe',
        userAvatar: 'https://randomuser.me/api/portraits/men/32.jpg',
        rating: 4,
        reviewText:
            'The food was delicious! Highly recommend trying their specials.',
        date: '20th June, 2024',
    },
    {
        id: 2,
        userName: 'Jane Smith',
        userAvatar: 'https://randomuser.me/api/portraits/women/12.jpg',
        rating: 5,
        reviewText:
            'Great experience, loved the ambiance and the food quality.',
        date: '18th June, 2024',
    },
    // Add more review items as needed
];
export default reviewsData;
import React from "react";
import { Typography, Box, Grid, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import ReactPlayer from "react-player";
import SearchBar from "../../../components/search_bar/search_bar_main";
import SocialMediaBar from "../../../components/social_media_bar/social_media_bar_main";
import DiscountOfferSection from "./discountOfferSection";
import aboutData from "./aboutDataSet";

const CustomDivider = styled(Divider)(({ theme }) => ({
  width: "30%",
  margin: "auto",
  "&::before, &::after": {
    borderColor: "#EAA017",
    borderWidth: 2,
  },
  [theme.breakpoints.down("md")]: {
    width: "50%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
}));

function AboutDescription() {
  return (
    <>
      <SearchBar />
      <SocialMediaBar />
      <Box
        sx={{
          // backgroundColor:"red",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // margin: "0px 0px 0px 0",
        }}
      >
        <CustomDivider
          className="responsive_fontsize32"
          sx={{
            marginTop: {
              xs: "5px",
              sm: "8px",
              md: "10px",
              lg: "15px",
              xl: "20px",
            },
             fontWeight: "600",
           }}
        >
          About
        </CustomDivider>
      </Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{
            // backgroundColor:"yellow",
            padding: {
              xs: "10px",
              sm: "10px",
              md: "12px",
              lg: "15px",
              xl: "20px",
            }, }}>
            <Typography
              className="responsive_fontsize24"
              sx={{
                marginBottom: {
                  xs: "5px",   // Extra small screens (mobile)
                  sm: "8px",   // Small screens (tablets)
                  md: "10px",  // Medium screens (small laptops)
                  lg: "10px",  // Large screens (desktops)
                  xl: "10px",  // Extra large screens (large desktops)
                },
                fontWeight: "400",
              }}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum
              eligendi omnis harum temporibus quia corrupti sunt soluta iusto
              fugiat laudantium, a illum dolores nisi ipsum.
            </Typography>
            <Typography
              className="responsive_fontsize20"
              sx={{
                fontWeight: "300",
              }}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus
              explicabo repellat, corporis veritatis molestiae ipsam! In eaque
              facilis voluptas, aut rerum sequi necessitatibus reprehenderit a,
              error, repudiandae quae optio quas consequuntur officia.
              Necessitatibus nisi fuga nesciunt corrupti quasi neque
              repellendus!
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              margin: {
                xs: "10px", // Small screens (mobile)
                sm: "15px", // Small to medium screens
                md: "20px", // Medium screens
                lg: "20px", // Large screens
                xl: "20px", // Extra large screens
              },
              // backgroundColor:"yellow",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ReactPlayer
              style={{
                width: "100%",
                height: "auto",
                maxHeight: 300,
                maxWidth: "100%",
              }}
              url="https://www.youtube.com/watch?v=mTFYCX7bJ6w&pp=ygUsZm9vZCBjb29raW5nIGluIHJlc3RhdXJlbnQgYnkgY2hpZWYgaW5kaWE%3D"
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{marginTop: {
      xs: "5px",   // Extra small screens (mobile)
      sm: "10px",  // Small screens (tablets)
      md: "12px",  // Medium screens (small laptops)
      lg: "15px",  // Large screens (desktops)
      xl: "15px",  // Extra large screens (large desktops)
    } }}>
        <Typography
          className="responsive_fontsize28"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          Why to choose BBG?
        </Typography>
        <Box sx={{ flexGrow: 1 ,
        marginTop: {
                xs: "15px", // Small screens (mobile)
                sm: "18px", // Small to medium screens
                md: "20px", // Medium screens
                lg: "20px", // Large screens
                xl: "20px", // Extra large screens
              },}}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {aboutData.map((item) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                <DiscountOfferSection
                content={item}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default AboutDescription;
import React from "react";
import { Typography } from "@mui/material";
import SearchBar from "src/components/search_bar/search_bar_main"; // Adjust path as needed
import SocialMediaBar from "src/components/social_media_bar/social_media_bar_main"; // Adjust path as needed
import SubscriptionSlider from "./SubscriptionSlider";

const SubscriptionPackages = () => {
  return (
    <>
      <SearchBar />
      <SocialMediaBar />
      <Typography
        variant="h5"
        className="responsive_fontsize26"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          mt: "8rem",
          mb: "2rem",
          color: "black",
        }}
      >
        "Say goodbye to meal planning stress and hello to subscription bliss -
        where good food is just a click away."
      </Typography>
      <SubscriptionSlider /> {/* Use the slider component here */}
    </>
  );
};

export default SubscriptionPackages;

import { Box } from "@mui/material";
import React from "react";
import SecondSectionComponent from "./second_section_component";

function MenuSecondSection() {
  return (
    <Box
      sx={{
        display:{xs:"column", md:"flex"},
        justifyContent:"space-between",
   
      }}
    >
      <SecondSectionComponent />
    </Box>
  );
}

export default MenuSecondSection;

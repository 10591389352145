import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  markerPosition: { lat: 27.7172, lng: 85.324 },
  destinationPosition: null,
  directionsResponse: null,
  distance: '',
  duration: '',
  locationText: '',
  isGeolocationActive: false,
};

const customerLocationSlice = createSlice({
  name: 'customerLocation',
  initialState,
  reducers: {
    setMarkerPosition: (state, action) => {
      state.markerPosition = action.payload;
    },
    setDestinationPosition: (state, action) => {
      state.destinationPosition = action.payload;
    },
    setDirectionsResponse: (state, action) => {
      state.directionsResponse = action.payload;
    },
    setDistance: (state, action) => {
      state.distance = action.payload;
    },
    setDuration: (state, action) => {
      state.duration = action.payload;
    },
    setLocationText: (state, action) => {
      state.locationText = action.payload;
    },
    setGeolocationActive: (state, action) => {
      state.isGeolocationActive = action.payload;
    },
  },
});

export const {
  setMarkerPosition,
  setDestinationPosition,
  setDirectionsResponse,
  setDistance,
  setDuration,
  setLocationText,
  setGeolocationActive,
} = customerLocationSlice.actions;

export default customerLocationSlice.reducer;

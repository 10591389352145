// ProfileHeader.js
import React from "react";
import { Box, Avatar, Typography, IconButton } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";

function ProfileHeader({ profilePicture, userName, address, onEditProfileClick, fileInputRef, onProfilePictureChange }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
      <Box sx={{ position: "relative", display: "inline-block" }}>
        <Avatar alt="Profile Picture" src={profilePicture} sx={{ width: 120, height: 120, marginBottom: 2 }} />
        <input
          ref={fileInputRef}
          accept="image/*"
          style={{ display: "none" }}
          id="icon-button-file"
          type="file"
          onChange={onProfilePictureChange}
        />
        <label htmlFor="icon-button-file">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            sx={{
              position: "absolute",
              bottom: 4,
              right: 4,
              backgroundColor: "white",
              borderRadius: "50%",
              p: 0.5,
              boxShadow: 1,
            }}
            onClick={onEditProfileClick}
          >
            <PhotoCamera />
          </IconButton>
        </label>
      </Box>
      <Typography className="responsive_fontsize25" variant="h5" sx={{ fontWeight: "600", marginBottom: 1.2 }}>
        {userName}
      </Typography>
      {/* <Typography className="responsive_fontsize25" variant="h5" sx={{ fontWeight: "600", marginBottom: 1.2 }}> */}
        {/* {address} */}
      {/* </Typography> */}
    </Box>
  );
}

export default ProfileHeader;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  incrementMenuItemQuantity,
  decrementMenuItemQuantity,
  // removeItemFromMenu,
} from "../../../../redux/slices/menuSlice";
import { styled } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const NumberInput = ({ menuName, itemId, quantity = 1 }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(quantity);

  useEffect(() => {
    setInputValue(quantity || 1); // Ensure it starts at 1 if quantity is 0 or undefined
  }, [quantity]);

  const handleIncrement = () => {
    setInputValue((prevValue) => prevValue + 1);
    dispatch(incrementMenuItemQuantity({ menuName, itemId }));
  };

  const handleDecrement = () => {
    if (inputValue > 1) {
      setInputValue((prevValue) => prevValue - 1);
      dispatch(decrementMenuItemQuantity({ menuName, itemId }));
    }
  };

  const handleInputChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    if (!isNaN(newValue) && newValue >= 1) {
      setInputValue(newValue);
    }
  };

  return (
    <StyledInputRoot>
      <StyledButton onClick={handleDecrement} disabled={inputValue <= 1}>
        <RemoveIcon className="responsive_fontsize20" />
      </StyledButton>
      <StyledInput
        type="number"
        value={inputValue}
        onChange={handleInputChange}
        min={1}
        max={1000}
        onWheel={(e) => e.target.blur()} // Disable mouse wheel changing the number
      />
      <StyledButton onClick={handleIncrement}>
        <AddIcon className="responsive_fontsize20" />
      </StyledButton>
    </StyledInputRoot>
  );
};

// Styled components for consistent UI
const StyledInputRoot = styled("div")`
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 300;
  display: flex;
  align-items: center;
`;

const StyledInput = styled("input")`
  font-size: 0.85rem;
  font-family: inherit;
  font-weight: 300;
  padding: 5px;
  background: none;
  width: 3rem;
  border: 1px solid black;
  text-align: center;

  /* Hide the number input arrows */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Hides arrows in Firefox */
  -moz-appearance: textfield;
`;

const StyledButton = styled("button")`
  font-family: "IBM Plex Sans", sans-serif;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    outline: 0;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export default NumberInput;

import React, {
  // useState,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Header from "./cardListingHeader";
import CategorySection from "./categorySection";
import FoodItemsGrid from "./foodItemsGrid";
import MenuContainer from "./menuContainer";
import {
  setAnchorElId,
  closePopover,
  setSelectedCategory,
  setCurrentMenuIndex,
  openMenuNameDialog,
  closeMenuNameDialog,
  addMenu,
  addItemToMenu,
  setMenuName,
  setFoodItemToAdd,
} from "../../../../redux/slices/menuSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function MakeMenu({ foodOrdered }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {openPopover, selectedCategory, joinData, menuName, foodItemToAdd,} = useSelector((state) => state.menus);
  const selectedMenus = useSelector((state) => state.menus.selectedMenus);
  const currentMenu = useSelector((state) => state.menus.currentMenu);
  
  const categories = [
    ...new Set((joinData[0] || []).map((item) => item.item_name)),
  ];

  const { isAuthenticated } = useSelector((state) => state.auth);
  const axiosInstance = axios.create({
    baseURL: "https://www.bbgrestaurant.com/bbgapi", // replace with your actual API endpoint
    headers: {
      "Content-Type": "application/json",
    },
  });

  // Filter food items based on the selected category
  useEffect(() => {
    if (selectedCategory) {
      dispatch(setSelectedCategory(selectedCategory));
    }
  }, [selectedCategory, dispatch]);

  const handleOpenMenuNameDialog = (event, fooditem) => {
    console.log("Opening menu name dialog...", event, fooditem);
    if (!isAuthenticated) {
      toast.error("Please login first to create a menu.");
      dispatch(closeMenuNameDialog());
      navigate("/login"); // Redirect to login page
      return;
    }

    try {
      if (!event || !event.currentTarget || fooditem === undefined) {
        console.error("Event or food item is missing.");
        return;
      }
      console.log("Triggering dialog with:", event.currentTarget.id, fooditem);

      // Closing any open popovers
      const popoverID1 = fooditem ? fooditem.id : "header";
      const newAnchorId = event.currentTarget.id;
      // dispatch(closePopover());
      dispatch(setAnchorElId(newAnchorId));

      dispatch(closePopover());
      dispatch(setMenuName(""));
      // Open the menu name dialog
      dispatch(
        openMenuNameDialog({
          anchorElId: newAnchorId,
          popoverID: popoverID1,
          foodItem: fooditem ? { ...fooditem, quantity: 1 } : null,
        })
      );
    } catch (error) {
      console.error("Error in food item grid open menu dialog:", error);
    }
  };

  const handleSaveMenuName = async () => {
    if (!menuName.trim()) {
      toast.error("Menu name cannot be empty.");
      return;
    }
  
    if (selectedMenus.some((menu) => menu.menuName === menuName)) {
      toast.warn("Menu name already exists");
      dispatch(closeMenuNameDialog());
      return;
    }
  
    // Safely find the menu
    const menu = selectedMenus.find((menu) => menu.menuName === menuName);
  
    // Ensure that the menu exists and has selectedItems
    if (!menu || !menu.selectedItems) {
      toast.error("No items selected for this menu.");
      return;
    }
  
    const selectedItems = menu.selectedItems || [];
  
    const saveMenu = {
      menu_name: menuName,
      menu_items: selectedItems.map(item => ({
        id: item.id,
        name: item.name,
        quantity: item.quantity,
        price: item.price,
      })),
      item_quantity: selectedItems.length,
      total_price: selectedItems.reduce((total, item) => total + item.price * item.quantity, 0),
      menu_schedule: "nvehiurhke hvnehior", // Example placeholder schedule
      menu_cid: localStorage.getItem("customerId"),
      table_number: "5", // Assuming table number is static or dynamic based on context
    };
  
    try {
      const response = await axiosInstance.post("/makeMenu", saveMenu);
      if (response.data) {
        toast.success("Menu created and saved.");
        dispatch(addMenu({ menuName }));
  
        if (foodItemToAdd) {
          dispatch(addItemToMenu({ menuName, item: foodItemToAdd }));
          toast.success("Item added to the menu.");
        } else {
          toast.info("Menu created with no items.");
        }
      } else {
        toast.error("Failed to save the menu.");
      }
    } catch (error) {
      toast.error("Error saving the menu. Please try again.");
      console.error(error);
    }
  
    dispatch(closeMenuNameDialog());
    dispatch(closePopover());
  };
  
  const handleInputChange = (e) => {
    dispatch(setMenuName(e.target.value));
  };

  const handleClose = () => {
    dispatch(closeMenuNameDialog());
    dispatch(setMenuName(""));
    dispatch(setFoodItemToAdd(null)); // Assuming this action exists
  };

  return (
    <>
      <Header
        setAnchorEl={(el) => dispatch(setAnchorElId(el))}
        openPopover={openPopover}
        handleClosePopover={() => dispatch(closePopover())}
        selectedMenus={selectedMenus}
        handleOpenMenuNameDialog={handleOpenMenuNameDialog}
        handleInputChange={handleInputChange}
        handleSaveMenuName={handleSaveMenuName}
      />

      <CategorySection
        categories={categories}
        selectedCategory={selectedCategory}
        setSelectedCategory={(category) =>
          dispatch(setSelectedCategory(category))
        }
      />

      {/* Passing concatenated subcategory and item name */}
      <FoodItemsGrid
        selectedCategory={selectedCategory}
        selectedMenus={selectedMenus}
        currentMenu={currentMenu}
        handleOpenMenuNameDialog={handleOpenMenuNameDialog}
        handleClose={handleClose}
        handleSaveMenuName={handleSaveMenuName}
        handleInputChange={handleInputChange}
      />

      <MenuContainer
        selectedMenus={selectedMenus}
        currentMenu={currentMenu}
        foodOrdered={foodOrdered}
        setCurrentMenuIndex={setCurrentMenuIndex}
        handleSaveMenuName={handleSaveMenuName}
      />
    </>
  );
}

MakeMenu.propTypes = {
  foodOrdered: PropTypes.array.isRequired,
};

MakeMenu.defaultProps = {
  foodOrdered: [],
};

export default MakeMenu;

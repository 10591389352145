const comments = [
    {
        id: 1,
        name: "John",
        comment: "Last week we had a chance to experience the new..."
    },
    {
        id: 2,
        name: "Aisha",
        comment: "Amazing dish, would definitely recommend..."
    },
    {
        id: 3,
        name: "Mohan",
        comment: "The flavors were amazing, and the presentation..."
    }
];

export default comments;

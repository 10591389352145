import React from 'react';
import {
    Box, 
    Grid,
    Typography,
} from '@mui/material';

function CustomerConfirmationInfo({deliveryDetails, paymentMethod,orderId}) {
  const {fullName, locationText, mobileNumber, emailAddress, deliveryTime, foodInstructions, addressInstructions} = deliveryDetails;
  return (
    <div>
      <Typography className="responsive_fontsize20" fontWeight="bold">Your info:</Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}>
            <Grid container rowSpacing={1}>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize16">Name</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize14">{fullName}</Typography>
              </Grid>
            </Grid>

            <Grid container rowSpacing={1}>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize16">Location</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize14">{locationText}</Typography>
              </Grid>
            </Grid>

            <Grid container rowSpacing={1}>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize16">Phone{" "}</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize14">{mobileNumber}</Typography>
              </Grid>
            </Grid>

            <Grid container rowSpacing={1}>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize16">Email</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize14">{emailAddress}</Typography>
              </Grid>
            </Grid>

            <Grid container rowSpacing={1}>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize16">Delivery Time</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize14">{deliveryTime}</Typography>
              </Grid>
            </Grid>

            <Grid container rowSpacing={1}>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize16">Food Instructions</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize14">{foodInstructions}</Typography>
              </Grid>
            </Grid>

            <Grid container rowSpacing={1}>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize16">Address Instructions</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize14"> {addressInstructions}</Typography>
              </Grid>
            </Grid>

            <Grid container rowSpacing={1}>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize16"> Payment Method</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize14">{paymentMethod}</Typography>
              </Grid>
            </Grid>
          </Box>
    </div>
  )
}

export default CustomerConfirmationInfo;

import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import esewa from "../order_list/payment_assets/esewa_logo.png";
import khalti from "../order_list/payment_assets/khalti_logo.png";
import fonepay from "../order_list/payment_assets/fonepay_logo.png";
import mobBanking from "../order_list/payment_assets/mob_banking.png";
// import axios from "axios";

function CheckoutPayment({ onPaymentMethodChange }) {
  // const [amount] = useState(100); // Changed to number type
  // const [taxAmount] = useState(10); // Changed to number type
  // const [transactionUuid] = useState("unique-uuid-123");
  // const [productCode] = useState("EPAYTEST");
  // const [successUrl] = useState("https://esewa.com.np");
  // const [failureUrl] = useState("https://google.com");
  // const [paymentResponse, setPaymentResponse] = useState(null); // State to hold payment response
  const [paymentMethod, setPaymentMethod] = useState("Cash On Delivery"); // Default payment method
  const [paymentResponse] = useState(null);

  // const handleSubmit = async (e) => {
  //     e.preventDefault(); // Prevent default form submission

  //     try {
  //         const response = await axios.post('http://localhost:8001/bbgapi/payment', {
  //             amount,
  //             transaction_uuid: transactionUuid,
  //             product_code: productCode,
  //             tax_amount: taxAmount,
  //         });

  //         console.log("response", response.data.data);

  //         if (response.data.success === "success") {
  //             const { signed_field_names, signature } = response.data.paymentHash;
  //             const form = document.createElement('form');
  //             form.method = 'POST';
  //             form.action = 'https://rc-epay.esewa.com.np/api/epay/main/v2/form';
  //             const fields = {
  //                 total_amount: amount + taxAmount, // Calculate total amount
  //                 transaction_uuid: transactionUuid,
  //                 product_code: productCode,
  //                 product_service_charge: "0",
  //                 product_delivery_charge: "0",
  //                 success_url: successUrl,
  //                 failure_url: failureUrl,
  //                 signed_field_names,
  //                 signature,
  //             };
  //             console.log("response", fields)
  //             // Log the signature for debugging
  //             console.log("signature", signature);

  //             // Instead of submitting a form, store the response
  //             setPaymentResponse({ success: true, fields });

  //             // Append inputs to the form
  //             Object.keys(fields).forEach((key) => {
  //               const input = document.createElement('input');
  //               input.type = 'hidden';
  //               input.name = key;
  //               input.value = fields[key];
  //               form.appendChild(input);
  //           });

  //           document.body.appendChild(form);
  //           form.submit(); // Submit the form to eSewa
  //         }
  //     } catch (error) {
  //         console.error("Payment submission error:", error);
  //         setPaymentResponse({ success: false, message: error.message }); // Handle error response
  //     }
  // };
  const handlePaymentChange = (event) => {
    const selectedMethod = event.target.value;
    setPaymentMethod(selectedMethod);
    onPaymentMethodChange(selectedMethod); // Pass selected method to parent
  };

  return (
    <Box>
      <FormControl>
        <RadioGroup
          aria-labelledby="payment-method"
          value={paymentMethod}
          // onChange={(e) => setPaymentMethod(e.target.value)}
          onChange={handlePaymentChange}
          name="payment-method"
        >
          <FormControlLabel
            value="Cash On Delivery"
            control={<Radio />}
            label="Cash On Delivery"
          />
          <FormControlLabel
            value="Esewa"
            control={<Radio />}
            label={
              <img
                src={esewa}
                alt="eSewa logo"
                style={{ height: "20px", width: "70px" }}
              />
            }
          />
          <FormControlLabel
            value="Khalti"
            control={<Radio />}
            label={
              <img
                src={khalti}
                alt="Khalti logo"
                style={{ height: "30px", width: "90px" }}
              />
            }
          />
          <FormControlLabel
            value="FonePay"
            control={<Radio />}
            label={
              <img
                src={fonepay}
                alt="Fonepay logo"
                style={{ height: "28px", width: "100px" }}
              />
            }
          />
          <FormControlLabel
            value="Mobile Banking"
            control={<Radio />}
            label={
              <img
                src={mobBanking}
                alt="Mobile banking logo"
                style={{ height: "18px", width: "100px" }}
              />
            }
          />
        </RadioGroup>
      </FormControl>
      {/* <Button onClick={handleSubmit} variant="contained" color="primary">
                Proceed to Payment 
            </Button> */}

      {paymentResponse && (
        <Box mt={2}>
          {paymentResponse.success ? (
            <Typography variant="h6" color="green">
              Payment successful! Redirecting...
              {/* Add your logic here to redirect or handle the payment response */}
            </Typography>
          ) : (
            <Typography variant="h6" color="red">
              Payment failed: {paymentResponse.message}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}

export default CheckoutPayment;

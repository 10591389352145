import React from 'react';
import { Box, Button } from '@mui/material';

const PaginationControls = ({
  filteredItems = [],
  displayedItems = [],
  itemsPerPage,
  handleMoreClick,
  handleLessClick,
}) => {
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const currentPage = Math.ceil(displayedItems.length / itemsPerPage);

  return (
    <Box sx={{ mt: 3, display: 'flex', gap: 1 }}>
      {currentPage < totalPages && (
        <Button
          variant="outlined"
          className="responsive_fontsize18"
          onClick={handleMoreClick}
          sx={{ mr: 2 }}
        >
          See More
        </Button>
      )}
      {currentPage > 1 && (
        <Button
          variant="outlined"
          className="responsive_fontsize18"
          onClick={handleLessClick}
        >
          Show Less
        </Button>
      )}
    </Box>
  );
};

export default PaginationControls;

import React from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import SearchBar from "../../../components/search_bar/search_bar_main";
import SocialMediaBar from "../../../components/social_media_bar/social_media_bar_main";

const packageDetails = {
  Weekly: {
    price: "$20",
    benefits: ["Biryani", "Chicken momo", "Fried Rice"],
    stock: 7,
  },
  Monthly: {
    price: "$75",
    benefits: ["Chicken Chowmin", "Fried Rice", "Thukpa"],
    stock: 7,
  },
  Yearly: {
    price: "$800",
    benefits: ["Burger", "Fish Fried", "Chilly Curry"],
    stock: 7,
  },
};

const AvailablePlanCard = () => {
  const { frequency } = useParams();
  const navigate = useNavigate();

  // Ensure frequency is defined and not empty before accessing its properties
  const plan = frequency
    ? packageDetails[frequency.charAt(0).toUpperCase() + frequency.slice(1)] ||
      {}
    : {};

  const [subscribed, setSubscribed] = React.useState(false); // State to track subscription status
  const [openSubscribeDialog, setOpenSubscribeDialog] = React.useState(false); // Dialog for subscription success
  const [openRemoveDialog, setOpenRemoveDialog] = React.useState(false); // Dialog for remove confirmation

  const handleRemoveCard = () => {
    setOpenRemoveDialog(false); // Close the remove confirmation dialog
    setSubscribed(false); // Reset subscribed state after removing the card
    setTimeout(() => {
      navigate("/subscription"); // Navigate back to Subscription after a delay
    }, 1000); // Adjust the delay as needed
  };

  const handleCloseDialog = () => {
    setOpenSubscribeDialog(false); // Close the subscription success dialog
    setOpenRemoveDialog(false); // Close the remove confirmation dialog
  };

  const handleBackToSubscription = () => {
    navigate("/subscriptionPackage"); // Navigate to '/subscriptionPackage'
  };

  const handleSubscribe = () => {
    setSubscribed(true); // Mark as subscribed
    setOpenSubscribeDialog(true); // Open the subscription success dialog
  };

  return (
    <>
      <Typography
        variant="h5"
        className="responsive_fontsize24"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          mt: "2rem",
          mb: "2rem",
          color: "black",
        }}
      >
        "Say goodbye to meal planning stress and hello to subscription bliss -
        where good food is just a click away."
      </Typography>
      <Grid
        container
        justifyContent="center"
        sx={{ marginTop: "2rem", marginBottom: "2rem" }}
      >
        <Grid item xs={10} sm={8} md={4}>
          <Card
            sx={{
              width: "100%",
              height: "auto",
              backgroundColor: "#261d1d",
              color: "white",
              padding: "0.5rem",
              mt: "3rem",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 2)",
            }}
          >
            <CardContent sx={{ padding: "0.5rem" }}>
              <Typography variant="h5" sx={{ mb: 1, fontWeight: "bold" }}>
                {frequency &&
                  frequency.charAt(0).toUpperCase() + frequency.slice(1)}{" "}
                Package
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Price: {plan.price}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Benefits:
              </Typography>
              <List>
                {plan.benefits &&
                  plan.benefits.map((benefit, index) => (
                    <ListItem key={index} sx={{ padding: "0.2rem 0" }}>
                      <ListItemText primary={benefit} />
                    </ListItem>
                  ))}
              </List>
              <Typography variant="caption" sx={{ mt: 1 }}>
                (Only <b>{plan.stock}</b> left in stock!)
              </Typography>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{ mt: 2 }}
              >
                <Button
                  className="responsive_fontsize16 button"
                  variant="contained"
                  color="primary"
                  sx={{
                    color: "white",
                    backgroundColor: "orange",
                    minWidth: "120px",
                  }}
                  onClick={handleBackToSubscription}
                >
                  Back to Package
                </Button>
                <Grid item>
                  {!subscribed ? (
                    <Button
                      className="responsive_fontsize16 button"
                      variant="contained"
                      color="success"
                      sx={{
                        color: "white",
                        backgroundColor: "green",
                        minWidth: "120px",
                        marginLeft: "10px",
                      }}
                      onClick={handleSubscribe} // Handle subscription process
                    >
                      Subscribe
                    </Button>
                  ) : (
                    <Button
                      className="responsive_fontsize16 button"
                      variant="contained"
                      color="error"
                      sx={{
                        color: "white",
                        backgroundColor: "red",
                        minWidth: "120px",
                        marginLeft: "10px",
                      }}
                      onClick={() => setOpenRemoveDialog(true)} // Open the remove confirmation dialog
                    >
                      Remove
                    </Button>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Dialog for subscription success */}
      <Dialog open={openSubscribeDialog} onClose={handleCloseDialog}>
        <DialogTitle>Subscribed Successfully!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have successfully subscribed to the {frequency} package.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for remove confirmation */}
      <Dialog open={openRemoveDialog} onClose={handleCloseDialog}>
        <DialogTitle>Are you sure you want to remove the package?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Once removed, you will no longer have access to the {frequency}{" "}
            package.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRemoveCard} color="error">
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <SearchBar />
      <SocialMediaBar />
    </>
  );
};

export default AvailablePlanCard;

   

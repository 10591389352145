import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { Box, useMediaQuery, Typography } from "@mui/material";
// import FoodCard from "../FoodCard/FoodCard";
import ButtonComponent from "../../../../components/ui/buttons/button_component";
import MenuPopoverComponent from "./menu_popover_component";
import SliderComponent from "./SliderComponent";
import { useSelector } from "react-redux";

const SecondSectionComponent = () => {
  const [selectedCategory, setSelectedCategory] = useState("veg");
  const [filteredItems, setFilteredItems] = useState([]);
  const buttonsContainerRef = useRef(null);

  const joinData = useSelector((state) => state.menus.joinData);
  const menuItems = useMemo(() => joinData[0] || [], [joinData]);

  const isXs = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const filterItems = useCallback(
    (category) => {
      if (!menuItems.length) return;

      let filtered = [];

      switch (category) {
        case "veg":
          filtered = menuItems.filter((item) =>
            item.item_subcategory?.toLowerCase().includes("veg")
          );
          break;
        case "non-veg":
          filtered = menuItems.filter((item) =>
            item.item_category?.toLowerCase().includes("non-veg")
          );
          break;
        case "Biryani":
          filtered = menuItems.filter((item) =>
            item.item_name?.toLowerCase().includes("biryani")
          );
          break;
        case "Palta":
          filtered = menuItems.filter((item) =>
            item.item_name?.toLowerCase().includes("palta")
          );
          break;
        case "momos":
          filtered = menuItems.filter((item) =>
            item.item_name?.toLowerCase().includes("momo")
          );
          break;
        default:
          filtered = menuItems;
          break;
      }

      setFilteredItems(filtered);
    },
    [menuItems]
  );

  useEffect(() => {
    filterItems(selectedCategory);
  }, [selectedCategory, filterItems]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {!isXs && !isSm && (
        <Typography
          className="responsive_fontsize48"
          fontWeight="bold"
          color="green"
          // marginBottom={3}
          textAlign={isXs || isSm ? "center" : "left"}
        >
          BBG SPECIAL
        </Typography>
      )}

      {isXs || isSm ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            // marginBottom: "5px",
          }}
        >
          <MenuPopoverComponent
            categories={["veg", "non-veg", "Biryani", "Palta", "momos"]}
            selectedCategory={selectedCategory}
            onChange={handleCategoryChange}
          />
        </Box>
      ) : null}

      <Box
        ref={buttonsContainerRef}
        sx={{
          display: "flex",
          flexDirection: isXs || isSm ? "column" : "row",
          gap: "10px",
          marginTop: isXs || isSm ? "5px" : 0,
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          visibility: isXs || isSm ? "hidden" : "visible",
        }}
      >
        {["veg", "non-veg", "Biryani", "Palta", "momos"].map((category) => (
          <ButtonComponent
            className="responsive_fontsize16"
            key={category}
            category={category}
            selectedCategory={selectedCategory}
            onClick={() => handleCategoryChange(category)}
            sx={{
              border: "1px solid black",
              backgroundColor:
                selectedCategory === category ? "#000000" : "#ffffff",
              color: selectedCategory === category ? "#ffffff" : "#000000",
              borderRadius: "20px",
              fontWeight: "bold",
              fontFamily: "Roboto Serif",
              padding: isXs ? "0.5rem 0.8rem" : isSm ? "0.5rem 1rem" : "0.5rem 1.5rem",
              transition: "all 0.3s ease-in-out",
              boxShadow:
                selectedCategory === category
                  ? "0px 4px 8px rgba(0, 0, 0, 0.2)"
                  : "0px 2px 4px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                color: "#ffffff",
                backgroundColor: "#000000",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
              },
            }}
          >
            {category}
          </ButtonComponent>
        ))}
      </Box>

      {/* Always use SliderComponent for displaying cards */}
      <SliderComponent key={selectedCategory} items={filteredItems} />

    </Box>
  );
};

export default SecondSectionComponent;

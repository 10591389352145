import { Box, styled, Typography } from "@mui/material";
import React from "react";
import mobileForeground from "../../assets/mobileForeground.png";
import mobileBackground from "../../assets/mobileBackground.png";
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import "./mobile_section.css";
const IconBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "1.5rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

function MobileDownloadMainSection() {
  return (
    <Box style={{ marginTop: "1rem", marginBottom: "1rem" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "4rem",
          alignItems: "center",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Box>
            <img src={mobileBackground} alt="Mobile Background" />
          </Box>
          <Box sx={{ position: "absolute", top: "20%", right: "20%" }}>
            <img src={mobileForeground} alt="Mobile Foreground" />
          </Box>
        </Box>
        <Box>
          <IconBox>
            <Box className="mobile-header__logobackground">
              <AppleIcon className="mobile-header__logo1" />
              <Box className="mobile-header__logodesc">
                <p className="mobile-header__logodesc--text1">GET IT ON</p>
                <h3 className="mobile-header__logodesc--text2">Apple Store</h3>
              </Box>
            </Box>
            <Box className="mobile-header__logobackground">
              <GoogleIcon className="mobile-header__logo2" />
              <Box className="mobile-header__logodesc">
                <p className="mobile-header__logodesc--text1">GET IT ON</p>
                <Typography className="mobile-header__logodesc--text2">Play Store</Typography>
              </Box>
            </Box>
          </IconBox>
        </Box>
      </Box>
    </Box>
  );
}

export default MobileDownloadMainSection;

import React from "react";
import Slider from "react-slick";
import { Box } from "@mui/material";
import FoodCard from "../FoodCard/FoodCard";

const SliderComponent = ({ items }) => {
  // console.log("items",items);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Slider {...settings}>
        {items.map((fooditem, index) => (
          <Box key={`${fooditem.id}-${index}`}>
            <FoodCard
              fooditem={fooditem}
              subcategory={`${fooditem.item_subcategory} ${fooditem.item_name}`} 
              price={fooditem.item_price}
              image={fooditem.item_image} 
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default SliderComponent;

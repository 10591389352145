import React from "react";
import { Box, Container } from "@mui/material";
import MenuFirstSection from "./components/menu_first_section/menu_first_section";
import MenuSecondSection from "./components/SpecialMenu/special_menu";

export default function MenuView() {
  return (
    <main>
      <Box component="section" sx={{ backgroundColor: "#FCF8F8" }}>
        <Container
          maxWidth="xl"
          sx={{
            mt: { xs: 12, sm: 10, md: 2, lg: 7 }, 
            display: "flex",
            flexDirection: "column",
            padding: { xs: 2, sm: 4, lg: 8 },
          }}
        >
          <MenuFirstSection />
          <MenuSecondSection />
        </Container>
      </Box>
    </main>
  );
}

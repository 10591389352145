export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
// Example of action types
export const CLEAR_CART = 'CLEAR_CART';
export const CLEAR_ORDER_LIST = 'CLEAR_ORDER_LIST';
export const CLEAR_ORDERED_ITEMS = 'CLEAR_ORDERED_ITEMS';
export const CONFIRM_ORDER = 'CONFIRM_ORDER';
// menu
export const ADD_MENU = 'ADD_MENU';
export const ADD_ITEM_TO_MENU = 'ADD_ITEM_TO_MENU';
export const REMOVE_ITEM_FROM_MENU = 'REMOVE_ITEM_FROM_MENU';
export const CLEAR_MENU = 'CLEAR_MENU';
export const CLEAR_ALL_MENUS = 'CLEAR_ALL_MENUS';

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import './enquiry.css';

function EnquiryDialog({ open, onClose, handleSubmit, name, setName, email, setEmail }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className="responsive_fontsize25">Enquiry Form</DialogTitle>
      <DialogContent>
        <DialogContentText className="responsive_fontsize18">
          Please fill out the form below to enquire about our restaurant.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Name"
          fullWidth
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          InputLabelProps={{
            className:"responsive-label",
          }}
        />
        <TextField
          margin="dense"
          label="Email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputLabelProps={{
            className:"responsive-label",
          }}
        />
        <TextField
          margin="dense"
          label="Contact Number"
          fullWidth
          variant="outlined"
          InputLabelProps={{
            className:"responsive-label",
          }}
        />
        <TextField
          // className="responsive_fontsize16"
          margin="dense"
          label="Message"
          fullWidth
          variant="outlined"
          multiline
          rows={4}
          InputLabelProps={{
            className:"responsive-label",
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button className="responsive_fontsize16" onClick={onClose}>
          Cancel
        </Button>
        <Button className="responsive_fontsize16" onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EnquiryDialog;

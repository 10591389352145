import React from 'react';
import { Box, Button } from '@mui/material';

const PaginationControls = ({
  currentPage,
  totalItems,
  itemsPerPage,
  handleMoreClick,
  handleLessClick,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
      {currentPage < totalPages - 1 && (
        <Button
          variant="outlined"
          className="responsive_fontsize18"
          onClick={handleMoreClick}
          sx={{ mr: 2 }}
        >
          See More
        </Button>
      )}
      {currentPage > 0 && (
        <Button
          variant="outlined"
          className="responsive_fontsize18"
          onClick={handleLessClick}
        >
          Show Less
        </Button>
      )}
    </Box>
  );
};

export default PaginationControls;

import {Box} from "@mui/material";
import FoodItemDetail from "./foodImage";

function PropertyDescriptionPage(){
    return(
        <>
         <Box sx={{
          mt:12
         }}>
          <FoodItemDetail/>
          </Box>
        </>
    )
}
export default PropertyDescriptionPage;
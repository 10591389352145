import React from "react";
import Slider from "react-slick";
import {
  Card,
  CardContent,
  Typography,
  Box,
  CardActions,
  Button,
  Grid,
} from "@mui/material";
import ComboFood from "../../assets/comboFood.jpg"; // Adjust path as needed
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { css } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

// Sample data
const packages = [
  {
    title: "Weekly Package",
    description: "Offering a Free Trial Period",
    frequency: "weekly",
  },
  {
    title: "Monthly Package",
    description: "Offering a Free Trial Period",
    frequency: "monthly",
  },
  {
    title: "Yearly Package",
    description: "Offering a Free Trial Period",
    frequency: "yearly",
  },
];

// Slider settings for React Slick
const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: false,
  autoplaySpeed: 5000,
};

const SubscriptionSlider = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <div>
      {/* Slick Slider for small screens */}
      {isSmallScreen && (
        <Slider
          {...sliderSettings}
          css={css`
            .slick-slide {
              padding: 0 1rem;
            }
            .slick-dots {
              bottom: -30px;
            }
          `}
        >
          {packages.map((pkg, index) => (
            <div key={index}>
              <Card
                sx={{
                  width: "100%",
                  maxWidth: "280px",
                  backgroundColor: "#261d1d",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  margin: "auto",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: "white" }}
                  >
                    {pkg.title}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2, color: "white" }}>
                    {pkg.description}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "white" }}>
                    (Only <b>7</b> left in stock!)
                  </Typography>
                </CardContent>

                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={ComboFood}
                    alt="Combo Food"
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "4px",
                    }}
                  />
                </Box>

                <CardActions
                  sx={{ justifyContent: "center", paddingBottom: "1rem" }}
                >
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        sx={{
                          color: "white",
                          backgroundColor: "#000",
                          width: "100%",
                          padding: "10px",
                          borderRadius: "4px",
                          "&:hover": {
                            backgroundColor: "#333",
                          },
                        }}
                        onClick={() => navigate("/make-your-menu")}
                      >
                        Make a Menu
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        sx={{
                          color: "white",
                          backgroundColor: "orange",
                          width: "100%",
                          padding: "10px",
                          borderRadius: "4px",
                          "&:hover": {
                            backgroundColor: "#ff8c00",
                          },
                        }}
                        onClick={() =>
                          navigate(`/availableplans/${pkg.frequency}`)
                        }
                      >
                        Available plans
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </div>
          ))}
        </Slider>
      )}

      {/* Grid View for larger screens */}
      {!isSmallScreen && (
        <Grid container spacing={4} justifyContent="center">
          {packages.map((pkg, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  width: "100%",
                  maxWidth: "325px",
                  backgroundColor: "#261d1d",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  margin: "auto",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: "white" }}
                  >
                    {pkg.title}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2, color: "white" }}>
                    {pkg.description}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "white" }}>
                    (Only <b>7</b> left in stock!)
                  </Typography>
                </CardContent>

                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={ComboFood}
                    alt="Combo Food"
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "4px",
                    }}
                  />
                </Box>

                <CardActions
                  sx={{ justifyContent: "center", paddingBottom: "1rem" }}
                >
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        sx={{
                          color: "white",
                          backgroundColor: "#000",
                          width: "100%",
                          padding: "10px",
                          borderRadius: "4px",
                          "&:hover": {
                            backgroundColor: "#333",
                          },
                        }}
                        onClick={() => navigate("/make-your-menu")}
                      >
                        Make a Menu
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        sx={{
                          color: "white",
                          backgroundColor: "orange",
                          width: "100%",
                          padding: "10px",
                          borderRadius: "4px",
                          "&:hover": {
                            backgroundColor: "#ff8c00",
                          },
                        }}
                        onClick={() =>
                          navigate(`/availableplans/${pkg.frequency}`)
                        }
                      >
                        Available plans
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};

export default SubscriptionSlider;

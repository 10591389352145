import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { keyframes } from "@emotion/react"; // Import keyframes for CSS animations

import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ExtraFoodSlider from "../offerSection/extraFood/sliderFood";

const blinkAnimation = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
`;

function SocialMediaBar() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(false);
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "50%",
        right: "20px",
        zIndex: "100",
        transform: "translate(0, 50%)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // backgroundColor: "blue"
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "0.125rem",
            height: "2.2rem",
            backgroundColor: "black",
            boxShadow: "0px 0px 15px 0px black",
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          mb: "0.2rem",
        }}
      >
        <IconButton>
          <FacebookIcon
            sx={{
              color: "black",
            }}
          />
        </IconButton>
        <IconButton>
          <InstagramIcon sx={{ color: "black" }} />
        </IconButton>
        <IconButton>
          <YouTubeIcon sx={{ color: "black" }} />
        </IconButton>
        <Box sx={{ textAlign: "center" }}>
          <IconButton
            sx={{
              animation: `${blinkAnimation} 1s infinite`,
              display: "flex",
              flexDirection: "column",
            }}
            onClick={handleOpen}
          >
            <LocalOfferIcon />
            <Typography variant="caption" sx={{ mt: 0.5, color: "black" }}>
              Offer
            </Typography>
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "0.2rem",
        }}
      >
        <Box
          sx={{
            width: "0.125rem",
            height: "2.2rem",
            backgroundColor: "black",
            boxShadow: "0px 0px 15px 0px black",
          }}
        ></Box>
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Typography
            className="responsive_fontsize32"
            sx={{ textAlign: "center", color: "#045307" }}
          >
            Special Offers
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: { xs: "10px", sm: "20px" } }}>
          <ExtraFoodSlider />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default SocialMediaBar;

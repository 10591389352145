import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import OTPSection from "./otpSection";
import SendOTPSection from "./sendOtpSection";
import ResetPasswordSection from "./resetPasswordSection";

const ForgotPasswordDialog = ({ open, onClose }) => {
  const [otpSent, setOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);

  const handleSendOTP = () => {
    setOtpSent(true);
  };

  const handleVerifyOTP = () => {
    setIsOtpVerified(true);
  };

  const handleResetPassword = () => {
    // Handle reset password logic here
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h5" sx={{ color: "green" }}>
          Forgot Password?
        </Typography>
      </DialogTitle>
      {!otpSent ? (
        <SendOTPSection onSendOTP={handleSendOTP} />
      ) : !isOtpVerified ? (
        <OTPSection onVerifyOTP={handleVerifyOTP} />
      ) : (
        <ResetPasswordSection onResetPassword={handleResetPassword} />
      )}
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ForgotPasswordDialog;

import { createSlice } from "@reduxjs/toolkit";
// import axios from 'axios';

// const customerUrl = 'https://www.bbgrestaurant.com/bbgapi';

const initialState = {
  isAuthenticated: false,
  customer: null,
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginRequest(state) {
      state.loading = true;
      state.error = null;
    },
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.customer = action.payload;
      state.loading = false;
      state.error = null;
    },
    loginFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    signupRequest(state) {
      state.loading = true;
      state.error = null;
    },
    signupSuccess(state) {
      state.loading = false;
      state.error = null;
    },
    signupFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.customer = null;
      state.loading = false; // Reset loading state
      state.error = null; // Reset error state
    },
  },
});

// Export actions
export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  signupRequest,
  signupSuccess,
  signupFailure,
  logout,
} = authSlice.actions;

// Export reducer
export default authSlice.reducer;

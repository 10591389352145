import React from "react";
import { Box, Typography, Button } from "@mui/material";
import spoon from "../../../assets/spoon.png";
import { addToCheckout, addToCart} from "../../../redux/slices/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Function to convert buffer to base64 string
const bufferToBase64 = (buffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

function HomeSecondSection() {
  
  const joinData = useSelector((state) => state.menus.joinData[0] || {});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const location = useLocation();
  
  // Ensure item exists safely
  const item = joinData[10];

 
  // Early return if no item is found in joinData
  if (!item) {
    return (
      <Box sx={{ textAlign: "center", padding: "2rem" }}>
        <Typography variant="h6">No item data available.</Typography>
      </Box>
    );
  }

  // Prepare data for display with fallback values
  const subcategory = `${item.item_subcategory || "Unknown Subcategory"} ${item.item_name || "Unnamed Item"}`.trim();
  const price = item.item_price ? JSON.parse(item.item_price)[0] : "Price not available";
  const description = item.item_description || "Description not available";
  
  const base64Image = item.item_image
    ? `data:image/png;base64,${bufferToBase64(item.item_image.data)}`
    : "/path/to/default/image.jpg"; // Use a default image in case item image is missing

  // Function to handle adding the item to cart and checkout
  const handleAddToCart = () => {
    const itemToAdd = {
      subcategory: subcategory, 
      price: price, // Ensure this has a safe fallback
      quantity: 1,
      image: base64Image,
      detail: item.item_description, // Subcategory is passed in correctly
    };

    // Dispatch actions to add to both checkout and cart
    dispatch(addToCheckout(itemToAdd));
    dispatch(addToCart(itemToAdd)); // Add to cart as well
    toast.success(`Added ${subcategory} to your checkout and cart`); // Update toast message

    navigate("/checkout");
  };

  return (
    <Box sx={{ mb: { xs: 5, sm: 10, md: 20 } }}>
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            width: "40%",
            height: "90%",
            background: "rgba(255, 238, 85, 0.50)",
            filter: "blur(151px)",
          }}
        ></Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            position: "relative",
            flexDirection: { xs: "column", md: "row" },
            mt: { xs: 3, sm: 5, md: 10, lg: 5 },
            gap: { xs: 5, sm: 7, md: 10, lg: 25 },
          }}
        >
          <Box
            sx={{
              height: { xs: "75%", sm: "80%", md: "70%" },
              width: { xs: "75%", sm: "80%", md: "60%" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              ml: { xs: 0, sm: 0, md: 5, lg: -11 }
            }}
          >
            <img
              src={base64Image}
              alt={subcategory}
              style={{
                width: "55%",
                height: "auto",
                borderRadius: "50%",
              }}
            />
          </Box>

          <Box sx={{ textAlign: "center", mt: { xs: "1rem", md: "0" } }}>
            <Typography
              className="responsive_fontsize18"
              sx={{ color: "#5E4915" }}
            >
              25% off Today
            </Typography>
            <Typography
              className="responsive_fontsize28"
              sx={{ fontWeight: "700" }}
            >
              {subcategory}
            </Typography>
            <Typography className="responsive_fontsize18">
              {description}
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              mt="1rem" 
              className="responsive_fontsize18"
            >
              <Typography sx={{ fontSize: "1rem" }}>Rs {`${price}`}</Typography>
              <Button
                variant="contained"
                onClick={handleAddToCart}  // No argument needed since subcategory is now handled within the component
                sx={{
                  backgroundColor: "#5E4915",
                  color: "white",
                  textTransform: "capitalize",
                  fontWeight: 400,
                  padding: "0.5rem 1.5rem",
                  fontSize: "1rem",
                  mt: "0.75rem",
                  "&:hover": {
                    backgroundColor: "#4a3911",
                  },
                }}
              >
                Order Now
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "flex" },
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          mt: {sm:10,md:-25},
          ml: 10,
        }}
      >
        <Box sx={{ width: { xs: "50%", md: "100%" }, height: "4rem" }}>
          <img src={spoon} alt="Spoon" width="100%" />
        </Box>
      </Box>
    </Box>
  );
}

export default HomeSecondSection;

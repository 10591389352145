import React from "react";
import { Helmet } from "react-helmet-async";

import ContactView from "src/views/contact";

function ContactPage() {
  return (
    <>
      <Helmet>
        <title>Contact | BBG</title>
        <meta name="description" content="Contact us" />
      </Helmet>
      <ContactView />
    </>
  );
}

export default ContactPage;

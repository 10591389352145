import React, { useState } from "react";
import { TextField, Button, DialogContent } from "@mui/material";

const SendOTPSection = ({ onSendOTP }) => {
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSend = () => {
    onSendOTP();
  };

  return (
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        label="Enter your email"
        type="email"
        fullWidth
        variant="standard"
        value={email}
        onChange={handleEmailChange}
      />
      <Button onClick={handleSend} variant="contained" color="primary" sx={{ mt: 2 }}>
        Send OTP
      </Button>
    </DialogContent>
  );
};

export default SendOTPSection;

const initialState = {
  selectedFoodItem: null,
  relatedFoodItems: [],
};

const foodReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SELECTED_FOOD_ITEM':
      return {
        ...state,
        selectedFoodItem: action.payload,
      };
    case 'SET_RELATED_FOOD_ITEMS':
      return {
        ...state,
        relatedFoodItems: action.payload,
      };
    default:
      return state;
  }
};

export default foodReducer;
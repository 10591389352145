// cartActions.js

export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const UPDATE_QUANTITY = "UPDATE_QUANTITY";
export const CLEAR_CART = "CLEAR_CART";
export const CLEAR_ORDER_LIST = "CLEAR_ORDER_LIST";
export const CLEAR_ORDERED_ITEMS = "CLEAR_ORDERED_ITEMS";
export const ADD_TO_ORDER_LIST = "ADD_TO_ORDER_LIST"; // New action for adding to alignOrderList
export const CONFIRM_ORDER = "CONFIRM_ORDER";

export const addToCart = (item) => ({
  type: ADD_TO_CART,
  payload: item,
});

export const removeFromCart = (id) => ({
  type: REMOVE_FROM_CART,
  payload: id,
});

export const updateQuantity = (id, quantity) => ({
  type: UPDATE_QUANTITY,
  payload: { id, quantity },
});

export const clearCart = () => {
  console.log('Clearing cart');
  return { type: 'CLEAR_CART' };
};

export const clearOrderList = () => ({
  type: CLEAR_ORDER_LIST,
});

export const clearOrderedItems = () => ({
  type: CLEAR_ORDERED_ITEMS,
});

export const addToOrderList = (items) => ({
  type: ADD_TO_ORDER_LIST,
  payload: items,
});

export const confirmOrder = () => ({
  type: CONFIRM_ORDER,
});

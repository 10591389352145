import React from "react";
import {Button, Typography, Accordion, AccordionSummary, AccordionDetails  } from "@mui/material";
import { ExpandMore as ExpandMoreIcon} from "@mui/icons-material";


const AdsAccordion = () => (
    <Accordion
    // expanded={nestedExpanded === "adPreferences"}
    // onChange={handleNestedAccordionChange("adPreferences")}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="ad-preferences-content"
      id="ad-preferences-header"
    >
      <Typography className="responsive_fontsize16">
        Ad Preferences
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Button variant="contained" color="primary" sx={{ mr: 2 }}>
        Accept Ads
      </Button>
      <Button variant="contained" color="secondary">
        Reject Ads
      </Button>
    </AccordionDetails>
  </Accordion>
);

export default AdsAccordion;

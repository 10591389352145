import React from "react";
import PropTypes from "prop-types";
import { Grid, Box } from "@mui/material";
import FoodCard from "../FoodCard/FoodCard";
import { useDispatch, useSelector } from "react-redux";
import {
  setAnchorElId,
  openPopover,
  closePopover
} from "src/redux/slices/menuSlice";

const FoodItemsGrid = ({selectedMenus, handleMenuUpdate, handleAddToMenu, handleOpenMenuNameDialog, handleClose, handleSaveMenuName, handleAddMenu, handleInputChange}) => {
  const dispatch = useDispatch();
  const {menuName, confirmDeleteDialog, anchorElId, selectedCategory, joinData, popoverID,} = useSelector((state) => state.menus);
  const filteredItems =joinData && joinData[0]  ? joinData[0].filter((item) => item.item_name === selectedCategory)  : [];
  
  const handleOpenPopover = (event, fooditem) => {
    console.log("food item and event in open popover", fooditem, event)
    if (event && event.currentTarget && fooditem) {
      const newAnchorElId = event.currentTarget.id;
      const popoverID1 = fooditem.id;
      // Concatenate subcategory and item name to distinguish items better
      const itemToAdd = {
        ...fooditem,
        subcategory: `${fooditem.item_subcategory} - ${fooditem.item_name}`,
      }
      dispatch(setAnchorElId(newAnchorElId));
      dispatch(openPopover({anchorElId: newAnchorElId, popoverID: popoverID1,foodItem: {...itemToAdd, quantity: 1,},}));
    } else {
      console.error("Event or event.currentTarget or fooditem is undefined");
    }
  };

  const handleClosePopover = () => {
    dispatch(closePopover(anchorElId));
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      {filteredItems.length > 0 ? (
        filteredItems.map((fooditem, index) => (
          <Grid item key={`${fooditem.item_id}-${index}`} xs={12} sm={6} md={4} lg={3}>
            <Box
              sx={{
                position: "relative",
                ml: { xs: 0, md: 10 },
                width: { xs: "100%", sm: "80%", md: "100%", lg: "95%" },
              }}
            >
              <FoodCard
                fooditem={fooditem}
                subcategory={`${fooditem.item_subcategory}  ${fooditem.item_name}`}
                selectedMenus={selectedMenus}
                isSelected={fooditem.quantity > 0}
                quantity={fooditem.quantity}
                handleMenuUpdate={handleMenuUpdate}
                handleAddToMenu={handleAddToMenu}
                handleOpenPopover={handleOpenPopover}
                handleClosePopover={handleClosePopover}
                handleOpenMenuNameDialog={(event) => handleOpenMenuNameDialog(event, fooditem || null)}
                handleClose={handleClose}
                menuName={menuName}
                confirmDeleteDialog={confirmDeleteDialog}
                popoverID={popoverID}
                handleSaveMenuName={handleSaveMenuName}
                handleAddMenu={handleAddMenu}
                handleInputChange={handleInputChange}
              />
            </Box>
          </Grid>
        ))
      ) : (
        <Box>No items available in this category.</Box>
      )}
    </Grid>
  );
};

FoodItemsGrid.propTypes = {
  selectedMenus: PropTypes.arrayOf(
    PropTypes.shape({
      menuName: PropTypes.string.isRequired,
      selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    })
  ).isRequired,
  handleMenuUpdate: PropTypes.func.isRequired,
  handleAddToMenu: PropTypes.func.isRequired,
};

export default FoodItemsGrid;

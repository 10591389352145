import React from "react";
import { Typography, Box, IconButton } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const MenuHeader = ({ currentMenu, handleTimeClick, currentMenuIndex, menuDates, menuTimes, menuName }) => {
  if (!currentMenu) {
    return null; // or some fallback UI
  }
  // if (currentMenuIndex < 0 || currentMenuIndex >= menuName.length) {
  //   console.error("Invalid menu index:", currentMenuIndex);
  //   return null; // Or some fallback UI
  // }

  return (
    <>
      <Typography className="responsive_fontsize16" variant="body1" sx={{ textAlign: "center",fontWeight:'bold' }}>
        Menu Name: {currentMenu}
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        <IconButton onClick={() => handleTimeClick(currentMenuIndex)}>
          <AccessTimeIcon sx={{ fontSize: 20 }} />
        </IconButton>

        <Typography variant="subtitle1">
          {/* Date: {menuDates[currentMenuIndex] || ""} */}
        </Typography>
        <Typography variant="subtitle1" sx={{ marginLeft: "10px" }}>
          {/* Time: {menuTimes[currentMenuIndex] || ""} */}
        </Typography>
      </Box>
    </>
  );
};

export default MenuHeader;
import React, { useState, useEffect } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import SearchBar from "src/components/search_bar/search_bar_main";
import SocialMediaBar from "src/components/social_media_bar/social_media_bar_main";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ItemList from "./ItemListContainer";
import PaginationControls from "./PaginationControl";

function VegItems() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Data from Redux
  const joinData = useSelector((state) => state.menus.joinData);
  // console.log("join data", joinData);
  const itemsPerPage = 3;
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplaySpeed: 1000,
    autoplay: true,
    slidesToScroll: 1,
    arrows: false,
  };

  const [filteredVegItems, setFilteredVegItems] = useState([]);
  const [displayedItems, setDisplayedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  // List of non-vegetarian ingredients and non-veg items to exclude


  useEffect(() => {

    const nonVegIngredients = ["chicken", "mutton", "buff", "fish", "egg", "wine"];
    const nonVegItemsByName = ["Wine"];

    if (joinData.length > 0) {
      const items = (joinData[0] || []).filter((item) => {
        const ingredients = JSON.parse(item.item_ingredients || "[]").map((ing) =>
          ing.toLowerCase()
        );
        console.log("veg items", items)

        const isNonVegByName = nonVegItemsByName.includes(item.item_name);
        const isVeg = !ingredients.some((ingredient) =>
          nonVegIngredients.includes(ingredient)
        );

        return isVeg && !isNonVegByName;
      });

      setFilteredVegItems(items);
      setDisplayedItems(items.slice(0, itemsPerPage));
    }
  }, [joinData,itemsPerPage]);

  const handleMoreClick = () => {
    const newPage = currentPage + 1;
    const startIndex = newPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const newItems = filteredVegItems.slice(startIndex, endIndex);
    setDisplayedItems((prev) => [...prev, ...newItems]);
    setCurrentPage(newPage);
  };

  const handleLessClick = () => {
    const newPage = currentPage - 1;
    if (newPage >= 0) {
      const startIndex = newPage * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;

      const newItems = filteredVegItems.slice(0, endIndex);
      setDisplayedItems(newItems);
      setCurrentPage(newPage);
    }
  };

  return (
    <>
      <SearchBar />
      <SocialMediaBar />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: 2,
          m: {
            xs: "7rem 20px",
            sm: "7rem 25px",
            md: "7rem 30px",
            lg: "7rem 35px",
          },
          px: { xs: 2, sm: 2, md: 5, lg: 10 },
        }}
      >
        <Typography
          className="responsive_fontsize24"
          sx={{
            fontWeight: 700,
            fontFamily: "roboto serif",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          Veg Items
        </Typography>
        <ItemList
          displayedItems={displayedItems}
          isMobile={isMobile}
          sliderSettings={sliderSettings}
        />
        {!isMobile && (
          <PaginationControls
            currentPage={currentPage}
            totalItems={filteredVegItems.length}
            itemsPerPage={itemsPerPage}
            handleMoreClick={handleMoreClick}
            handleLessClick={handleLessClick}
          />
        )}
      </Box>
    </>
  );
}

export default VegItems;

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const url='https://www.bbgrestaurant.com/bbgapi';
const initialState = {
  menus: [],
  selectedMenus: [],
  currentMenu: null,
  currentMenuIndex: null,
  openMenuDialog: false,
  confirmDeleteDialog: false,
  deleteMenuName: '',
  menuName: '',
  anchorElId: null,
  selectedCategory: 'Chowmein',
  foodItemToAdd: null,
  popoverID: null,
  newDate: "",
  newTime: "",
  location: { lat: null, lon: null },
  menuDates: [],
  menuTimes: [],
  joinData: [],
  loading: false,
  error: null,
  selectedItems: []
};

const menuSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    addMenu: (state, action) => {
      const { menuName, menuItems } = action.payload;
      const newMenu = { menuName, selectedItems: menuItems };

      // Check if a menu with the same name already exists
      const existingMenu = state.menus.find(menu => menu.menuName === menuName);

      if (!existingMenu) {
        // Add the new menu to both menus and selectedMenus
        state.menus.push(newMenu);
        state.selectedMenus.push(newMenu);
      }
    },
    addItemToMenu: (state, action) => {
      const { menuName, item } = action.payload;
      let menu = state.selectedMenus.find((menu) => menu.menuName === menuName);
    
      if (!menu) {
        // Create a new menu if it doesn't exist
        menu = { menuName, selectedItems: [] };
        state.menus.push(menu);
        state.selectedMenus.push(menu);
      }
    
      // Check if the item already exists in the menu
      const existingItem = menu.selectedItems.find((i) => i.id === item.id);
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        menu.selectedItems.push({ ...item, quantity: 1 });
      }
    
      // Update the current menu if applicable
      if (state.currentMenu?.menuName === menuName) {
        state.currentMenu = menu;
      }
    },
    
    updateMenu: (state, action) => {
      const { menuName, item } = action.payload;
      const newMenu = { menuName, selectedItems: [] };
      state.menus.push(newMenu);
      state.selectedMenus.push(newMenu);
      if (item) {
        state.selectedMenus[state.selectedMenus.length - 1].selectedItems.push(item);
      }
    },
    deleteMenu:   (state, action) => {
      const menuName = action.payload;
      state.menus = state.menus.filter(menu => menu.menuName !== menuName);
      state.selectedMenus = state.selectedMenus.filter(menu => menu.menuName !== menuName);
      if (state.currentMenu?.menuName === menuName) {
        state.currentMenu = null;
      }
    },
    incrementMenuItemQuantity: (state, action) => {
      const { menuName, itemId } = action.payload;
      const menu = state.selectedMenus.find(menu => menu.menuName === menuName);
      if (menu) {
        const item = menu.selectedItems.find(i => i.id === itemId);
        if (item) {
          item.quantity += 1;
        }
      }
    },
    decrementMenuItemQuantity: (state, action) => {
      const { menuName, itemId } = action.payload;
      const menu = state.selectedMenus.find(menu => menu.menuName === menuName);
      if (menu) {
        const item = menu.selectedItems.find(i => i.id === itemId);
        if (item && item.quantity > 1) {
          item.quantity -= 1;
        }
      }
    },
    removeItemFromMenu: (state, action) => {
      const { menuName, itemId } = action.payload;
      const menu = state.selectedMenus.find(menu => menu.menuName === menuName);
      if (menu) {
        menu.selectedItems = menu.selectedItems.filter(i => i.id !== itemId);
      }
    },
    setAnchorElId: (state, action) => {
      state.anchorElId = action.payload;
    },
    openPopover: (state, action) => {
      const { popoverID, anchorElId, foodItem } = action.payload || {};
      state.openPopover = !!popoverID;
      state.popoverID = popoverID || null;
      state.anchorElId = anchorElId || null;
      state.foodItemToAdd = foodItem || null;
      console.log("Popover opened with ID:", state.popoverID);
    },  
    closePopover: (state) => {
      state.openPopover = false;
      state.anchorElId = null;
      state.popoverID = null;
      state.menuName = null;
    },
    openMenuNameDialog: (state, action) => {
      const { foodItem, anchorElId, popoverID } = action.payload || {};
      console.log('openMenuNameDialog payload:', action.payload); // Log payload for debugging
      state.openMenuDialog = true;
      state.popoverID = popoverID; 
      state.anchorElId = anchorElId;
      state.menuName = action.payload.menuName || '';
      state.foodItemToAdd = foodItem;  
    },  
    closeMenuNameDialog: (state) => {
      state.openMenuDialog = false;
      state.anchorElId = null;
      state.popoverID = null;
      state.foodItemToAdd = null;
      state.menuName = null;
      state.currentMenu = null;
      console.log("setting current menu to null in closing menu dialog..")
    },
    openConfirmDeleteDialog: (state, action) => {
      state.confirmDeleteDialog = true;
      // state.deleteMenuName = action.payload;
    },
    closeConfirmDeleteDialog: (state) => {
      state.confirmDeleteDialog = false;
      state.deleteMenuName = null;
    },
    setMenuName: (state, action) => {
      state.menuName = action.payload;
    },
    setDeleteMenuName: (state, action) => {
      state.deleteMenuName = action.payload;
    },
    setCurrentMenu: (state, action) => {
      state.currentMenu = action.payload;
    },
    setCurrentMenuIndex: (state, action) => {
      state.currentMenuIndex = action.payload;
    },
    createMenuFromItems: (state, action) => {
      const { menuName, selectedItems } = action.payload;
      const menu = state.selectedMenus.find(menu => menu.menuName === menuName);
      if (menu) {
        menu.items = [...menu.items, ...selectedItems];
      }
    },
    setFoodItemToAdd: (state, action) => {
      state.foodItemToAdd = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setSelectedMenus: (state, action) => {
      state.selectedMenus = action.payload;
    },
    setJoinData: (state, action) => {
      state.joinData = action.payload;
    },
    fetchJoinDataRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchJoinDataSuccess: (state, action) => {
      state.joinData = action.payload;
      state.loading = false;
    },
    fetchJoinDataFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  addMenu,
  updateMenu,
  deleteMenu,
  addItemToMenu,
  addItemToSelectedMenu,
  removeItemFromMenu,
  incrementMenuItemQuantity,
  decrementMenuItemQuantity,
  openPopover,
  closePopover,
  openMenuNameDialog,
  closeMenuNameDialog,
  openConfirmDeleteDialog,
  closeConfirmDeleteDialog,
  setMenuName,
  selectedMenus,
  setDeleteMenuName,
  setCurrentMenu,
  setCurrentMenuIndex,
  createMenuFromItems,
  setAnchorElId,
  setSelectedCategory,
  setFoodItemToAdd,
  setJoinData,
  fetchJoinDataRequest,
  fetchJoinDataSuccess,
  fetchJoinDataFailure,
} = menuSlice.actions;

export const joinDataSelector = (state) => state.menu.joinData;
export const fetchJoinData = () => async (dispatch) => {
  dispatch(fetchJoinDataRequest());
  try {
    const response = await axios.get(`${url}/joinData`);
    const data = response.data.data; // Adjust if needed
    if (Array.isArray(data)) {
      dispatch(fetchJoinDataSuccess(data));
    } else {
      dispatch(fetchJoinDataFailure("Data is not an array"));
    }
  } catch (error) {
    dispatch(fetchJoinDataFailure(error.message));
  }
};

export default menuSlice.reducer;

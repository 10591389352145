import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  firstSectionFoodList: [
    {
      id: 1,
      name: "Chicken Burger",
      price: 100,
      ingredients: ["Chicken", "Bread", "Cheese", "Tomato", "Onion"],
      image: "https://picsum.photos/200",
    },
    {
      id: 2,
      name: "Leg Piece",
      price: 100,
      ingredients: ["Chicken", "Securet Masala", "Yogurt"],
      image: "https://picsum.photos/200",
    },
    {
      id: 3,
      name: "Chicken Biryani",
      price: 150,
      ingredients: ["Chicken", "Rice", "Masala"],
      image: "https://picsum.photos/200",
    },
    {
      id: 4,
      name: "Buff Momo",
      price: 199,
      ingredients: ["Buff", "Maida", "Masala"],
      image: "https://picsum.photos/200",
    },
    {
      id: 5,
      name: "Buff Momo",
      price: 199,
      ingredients: ["Buff", "Maida", "Masala"],
      image: "https://picsum.photos/200",
    },
  ],
  popularFoodList: [
    {
      id: 1,
      name: "Chicken Burger",
      price: 100,
      image: "",
    },
    {
      id: 1,
      name: "Chicken Biryani",
      price: 150,
      image: "",
    },
    {
      id: 1,
      name: "Chicken Soup",
      price: 100,
      image: "",
    },
  ],
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
});

export default homeSlice.reducer;

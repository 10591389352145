import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Rating,
} from "@mui/material";

function ReviewsComponent({ reviews }) {
  return (
    <Box>
      <List>
        {reviews.map((review) => (
          <ListItem key={review.id} alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt={review.userName} src={review.userAvatar} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {review.userName}
                  <Rating
                    name="rating"
                    value={review.rating}
                    precision={0.5}
                    readOnly
                    sx={{ ml: 1 }}
                  />
                </Box>
              }
              secondary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {review.reviewText}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {review.date}
                  </Typography>
                </>
              }
            />
          </ListItem>
        ))}
        {reviews.length === 0 && <Typography>No reviews available.</Typography>}
      </List>
    </Box>
  );
}
export default ReviewsComponent;

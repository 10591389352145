import React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Popover,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogContentText,
  // DialogTitle,
  // Button
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentMenu,
  addItemToMenu,
  closeMenuNameDialog,
  closePopover,
  openConfirmDeleteDialog,
} from "src/redux/slices/menuSlice";
import DeleteMenuPopover from "./deleteMenuPopover";

function FavoritePopover({
  open,
  onClosePopover,
  anchorEl,
  handleOpenMenuNameDialog,
  fooditem,
  handleOpenDelete, handleMenuDelete, handleDeleteDialog , deleteMenuName
}) {
  const dispatch = useDispatch();
  const { selectedMenus, foodItemToAdd , confirmDeleteDialog} = useSelector((state) => state.menus);

const handleMenuClick = (menuName) => {
  try {
    dispatch(setCurrentMenu(menuName));
    console.log("Current menu set successfully:", menuName);

    if (foodItemToAdd && typeof foodItemToAdd === 'object') {
      // console.log("Adding item to menu:", { menuName, item: foodItemToAdd });
      dispatch(addItemToMenu({ menuName, item: foodItemToAdd }));
    } else {
      console.log("not fooditem to add to menuclick via make menu add icon.")
    }
    // Close the dialog/popover only if an item is successfully added
    dispatch(closeMenuNameDialog());
    dispatch(closePopover());
  } catch (error) {
    console.error("Error in handleMenuClick:", error);
  }
};

  return (
    <> 

    <Popover
      anchorEl={anchorEl}
      id={"favorite-popover" || fooditem.id}
      open={open}
      onClose={onClosePopover}
      disableRestoreFocus
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <IconButton onClick={handleOpenMenuNameDialog}>
            <AddIcon />
          </IconButton>
          <Typography variant="h6">Make Your Menu!</Typography>
        </Box>
        <List>
          {selectedMenus.map((menu) => (
            <ListItem
              button
              key={menu.id} // Assuming each menu has a unique id
              onClick={() => handleMenuClick(menu.menuName)}
            >
              <ListItemText primary={menu.menuName} />

              {/* deleting the menu via the popover */}
              <ListItemSecondaryAction>
                <IconButton onClick={() => dispatch(openConfirmDeleteDialog(menu.menuName))}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>
    </Popover>

    {confirmDeleteDialog && (
      <DeleteMenuPopover 
        open = {confirmDeleteDialog}
        openDelete = {handleOpenDelete}
        confirmDelete = {handleMenuDelete}
        closeDelete = {handleDeleteDialog}
        deleteMenuName = {deleteMenuName}
      />
    )}
  </>
  );
}

FavoritePopover.propTypes = {
  open: PropTypes.bool.isRequired,
  onClosePopover: PropTypes.func.isRequired,
  anchorEl: PropTypes.object, // This could be PropTypes.instanceOf(Element) for more specificity
  onAddMenuName: PropTypes.func.isRequired, // Ensure this prop is required
  handleOpenMenuNameDialog: PropTypes.func.isRequired,
};

FavoritePopover.defaultProps = {
  anchorEl: null,
};

export default FavoritePopover;

const dataListTrack= [
    {
        id:1,
        title:'Order ID:',
        value:"2080-05-15-001X"
    },
    {
        id:2,
        title:'Custome Name:',
        value:"John Doe"
    },
    {
        id:3,
        title:'Address:',
        value:"Sanepa-02,Lalitpur"
    },
    {
        id:4,
        title:'Contact Number:',
        value:"johndoe@gmail.com"
    },
    {
        id:5,
        title:'Before:',
        value:"2:00 PM"
    },
    {
        id:6,
        title:'Payment Method:',
        value:"Credit Card"
    },
    {
        id:7,
        title:'Ordered Item:',
        value:"Chicken Briyani,Mo-Mo,Chowmein"
    },
    {
        id:8,
        title:'Allergic Item:',
        value:"Onion"
    },
    {
        id:9,
        title:'Total Amount:',
        value:"Rs.490"
    },
    {
        id:10,
        title:'Status:',
        value:"On the way"
    },
    {
        id:11,
        title:'Description:',
        value:"Near By EEC,300m East"
    },
]
export default dataListTrack;
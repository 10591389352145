import React, { useState } from "react";
import { Box } from "@mui/material";
import HomeCard from "./card_main";
import Slider from "react-slick";
import { useSelector } from "react-redux";

function HomeFirstSection() {
  const [sliderIndex, setSliderIndex] = useState(0);
  
  const [ingredientAnimateClass, setIngredientAnimateClass] = useState("");
  const joinData = useSelector((state) => state.menus.joinData);

  // const totalSlides = joinData[0]?.length || 0;
  // const maxDotsToShow =5;
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) =>  setSliderIndex(next),
    customPaging: (i) => {
      return (
        <div
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            background: i === sliderIndex ? "#656566" : "lightgray",
            display: "inline-block",
            margin: "0 3px",
          }}
        />
      );
    },
    appendDots: (dots) => {
      // Limit the number of dots to a maximum of 5
      const maxDotsToShow = 5;
      const limitedDots = dots.slice(0, maxDotsToShow);
      return (
        <ul style={{ display: "flex", justifyContent: "center", padding: 0
         }}>
          {limitedDots}
        </ul>
      );
    },
  };


  return (
    <Box
      sx={{
        // backgroundColor:"red",
        mt: { xs: 12, sm: 10, md: 12, lg: 10 },
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          top: "-10px",
          right: "-10px",
          left: "-10px",
          filter: "blur(10px)",
          background: "inherit",
        },
      }}
    >
      <Slider {...settings}>
        {joinData[0]?.map((food, index) => (
          <HomeCard
            key={food.item_id || index} // Ensure a unique key is provided
            food={food}
            sliderIndex={sliderIndex}
            setIngredientAnimateClass={setIngredientAnimateClass}
            ingredientAnimateClass={ingredientAnimateClass}
          />
        ))}
      </Slider>
    </Box>
  );
}

export default HomeFirstSection;

import React from 'react';
import SettingPage from './settingPage';

function SettingLoad() {
    return (
       <>
            <SettingPage/>
       </>
    );
}

export default SettingLoad;

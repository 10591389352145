import React from "react";
import { Button, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import isNight from "../../../utils/getTime";

function CardName({ food, price, handleAddToCart }) {  
  const navigate = useNavigate();

  // Handle menu navigation
  const handleMenuClick = () => {
    navigate("/menu");
  };

  // Handle offer navigation
  const handleOfferClick = () => {
    navigate("/offer");
  };

  // Concatenate subcategory and name
  const subcategory = `${food.item_subcategory} ${food.item_name}`;

  return (
    <Box sx={{ width: { xs: "70%", sm: "30%" }, position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          border: "1px solid green",
          filter: "blur(113px)",
        }}
      ></Box>
      <Box sx={{ mt: "10%", width: "100%" }}>
        <Box sx={{ width: "100%" }}>
          {isNight && (
            <Typography
              sx={{
                fontSize: "1.25rem",
                color: "#000",
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              Night Service
            </Typography>
          )}
          <Typography
            className="responsive_fontsize28"
            sx={{
              fontSize: { md: "3rem", sm: "2.8rem", xs: "1.5rem" },
              fontWeight: "700",
              color: "#000",
              textAlign: "center",
            }}
          >
            {subcategory}
          </Typography>

          <Box
            display="flex"
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            sx={{
              mt: { xs: "0.5rem", sm: "1.5rem" },
            }}
          >
            <Typography
              className="responsive_fontsize16"
              sx={{ fontSize: "1rem" }}
            >
              Rs {price}  {/* Use the price passed from joinData */}
            </Typography>
            <Button
              className="responsive_fontsize18"
              onClick={handleAddToCart} 
              variant="contained"
              sx={{
                color: "white",
                textTransform: "capitalize",
                fontWeight: "400",
                padding: "0.2rem 0.8rem",
                fontSize: "1rem",
                width: "fit-content",
                px: "1.5rem",
                mt: "0.75rem",
              }}
            >
              Order Now
            </Button>
          </Box>
        </Box>
        <Box
          className="responsive_fontsize18"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "2rem",
            mt: "2rem",
            justifyContent: "center",
          }}
        >
          <Button
            className="responsive_fontsize18"
            onClick={handleMenuClick}
            variant="contained"
            sx={{
              backgroundColor: "#000",
              color: "white",
              px: "3.5rem",
              textTransform: "capitalize",
              borderRadius: "1.2rem",
              height: "2.5rem",
            }}
          >
            Menu
          </Button>
          <Button
            className="responsive_fontsize18"
            onClick={handleOfferClick}
            variant="contained"
            sx={{
              backgroundColor: "#000",
              color: "white",
              fontSize: "1.25rem",
              textTransform: "capitalize",
              borderRadius: "1.2rem",
              px: "3.5rem",
              height: "2.5rem",
            }}
          >
            Offers
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default CardName;

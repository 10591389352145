import React from "react";
import { Helmet } from "react-helmet-async";

import AboutView from "src/views/about";
function AboutPage() {
  return (
    <>
      <Helmet>
        <title>About | BBG</title>
        <meta name="description" content="About us" />
      </Helmet>
      <AboutView />
    </>
  );
}

export default AboutPage;

import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Card from "./offerCard";
import UpcomingCard from "./upcomingOffer";
import FaceEmogi from "../../assets/Vector.png";
import SearchBar from "../search_bar/search_bar_main";
import SocialMediaBar from "../social_media_bar/social_media_bar_main";
import SpecialOffersDialog from "./specialOfferDialog"; 

function OfferSection() {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <Box sx={{ mt: 11 }}>
      <SearchBar />
      <SocialMediaBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px 0 10px 0",
        }}
      >
        <Typography
          className="responsive_fontsize48"
          sx={{
            textTransform: "uppercase",
            letterSpacing: "2px",
          }}
        >
          Bbg-offers
        </Typography>
        <Typography
          className="responsive_fontsize26"
          sx={{
            color: "#EAA017",
            letterSpacing: "1px",
            display: { xs: "none", md: "flex" },
          }}
        >
          Welcome to BBG
        </Typography>
        <Typography
          className="responsive_fontsize16"
          sx={{
            fontWeight: "300",
            color: "#000000",
            display: { xs: "none", md: "flex" },
          }}
        >
          Luxury, Quality & Hygiene
        </Typography>
        <Typography
          className="responsive_fontsize40"
          sx={{
            color: { xs: "#000000", sm: "#EAA017" },
          }}
        >
          25% off Today
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Typography
            className="responsive_fontsize48"
            sx={{
              color: { xs: "#EAA017", sm: "#000000" },
              marginRight: "10px",
            }}
          >
            Friday
          </Typography>
          <Typography
            className="responsive_fontsize58"
            sx={{
              color: { xs: "#EAA017", sm: "#000000" },
              marginRight: "10px",
            }}
          >
            Combo Offers
          </Typography>
          <Typography
            className="responsive_fontsize48"
            sx={{
              color: { xs: "#EAA017", sm: "#000000" },
              fontWeight: "400",
              marginRight: "10px",
            }}
          >
            up to
          </Typography>
          <Typography
            className="responsive_fontsize58"
            sx={{ color: { xs: "#000000", sm: "#EAA017" } }}
          >
            30% off
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: "100%",
            height: { xs: "3.5rem", sm: "4.5rem", md: "5.5rem", lg: "6.5rem" },
            width: { xs: "3.5rem", sm: "4.5rem", md: "5.5rem", lg: "6.5rem" },
            marginTop: { xs: "10px", sm: "20px" },
          }}
        >
          <img
            src={FaceEmogi}
            alt="emoji"
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          padding: "0 10px",
        }}
      >
        <Card />
      </Box>
      <Box
        sx={{
          marginTop: "20px",
          textAlign: "center",
        }}
      >
        <Typography className="responsive_fontsize40">
          Upcoming Events on Dashain Special
        </Typography>
        <UpcomingCard />
      </Box>
      <SpecialOffersDialog open={open} onClose={handleClose} />{" "}
    </Box>
  );
}
export default OfferSection;

import React from "react";
import Slider from "react-slick";
import { Box, IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useSelector } from "react-redux";
import ExtraFoodCard from "./extraFoodCard";
import { Buffer } from "buffer";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        backgroundColor: "transparent",
        color: "black",
        position: "absolute",
        top: "50%",
        right: "-50px",
        transform: "translateY(-50%)",
        zIndex: 1,
      }}
    >
      <ChevronRight />
    </IconButton>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      style={{
        backgroundColor: "transparent",
        color: "black",
        position: "absolute",
        top: "50%",
        left: "-50px",
        transform: "translateY(-50%)",
        zIndex: 1,
      }}
    >
      <ChevronLeft />
    </IconButton>
  );
}

function ExtraFoodSlider() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  // Get the joinData from the Redux store
  const joinData = useSelector((state) => state.menus.joinData);

  // Function to get the current price based on the time of day
  const getCurrentPrice = (priceData) => {
    // Get current hour
    const hour = new Date().getHours();

    // Determine if it's day or night (daytime from 6 AM to 6 PM)
    const isDay = hour >= 6 && hour < 18;

    // Parse item_price
    let prices = [];
    try {
      if (priceData) {
        prices = JSON.parse(priceData);
      }
    } catch (e) {
      console.error("Error parsing item_price:", e);
    }

    // Return the appropriate price based on time of day
    if (prices.length > 1) {
      return isDay ? prices[0] : prices[1]; // First price for day, second for night
    } else if (prices.length === 1) {
      return prices[0]; // Return the single price if only one is provided
    } else {
      return "Price not available";
    }
  };

  return (
    <Box
      sx={{
        padding: { xs: "10px 20px", md: "10px 50px" },
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Slider {...settings}>
        {(joinData[0] || []).map((item) => {
          // Convert buffer to base64 image format
          const buffer = item.item_image?.data || [];
          const base64Image = `data:${item.mimeType};base64,${Buffer.from(buffer).toString('base64')}`;

          // Get the current price for the time of day
          const currentPrice = getCurrentPrice(item.item_price);

          return (
            <div key={item.item_id}>
              <ExtraFoodCard
                image={base64Image} // Pass the converted image
                name={`${item.item_subcategory} ${item.item_name}`} // Combine subcategory and item name
                price={currentPrice}  // Pass the calculated price
              />
            </div>
          );
        })}
      </Slider>
    </Box>
  );
}

export default ExtraFoodSlider;

import React from 'react';
import {Box} from '@mui/material';
import ContactForm from './contactDetail';
function ContactLandingPage(){
    return(
        <>
        <Box sx={{mt:13}}>
            <ContactForm/>
        </Box>
        </>
    )
}
export default ContactLandingPage;
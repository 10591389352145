import React from "react";
import {
  Typography,
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchBar from "src/components/search_bar/search_bar_main";
import SocialMediaBar from "src/components/social_media_bar/social_media_bar_main";
import faqsData from "./faqsData";

// Styled divider with custom properties
const CustomDivider = styled(Divider)(({ theme }) => ({
  width: "30%",
  margin: "auto",
  "&::before, &::after": {
    borderColor: "#EAA017",
    borderWidth: 2,
  },
  [theme.breakpoints.down("md")]: {
    width: "50%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
}));

const FAQSection = () => {
  return (
    <>
    <Box sx={{ mt: 13,
      //  padding: { xs: "10px", sm: "15px", md: "20px" }
        }}>
      <SearchBar />
      <SocialMediaBar />

      {/* Title Section */}
      <Box
        sx={{
          // backgroundColor:"red",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // margin: "0px 0px 0px 0",
        }}
      >
        <CustomDivider
          sx={{
            marginTop: {
              xs: "5px",
              sm: "8px",
              md: "10px",
              lg: "15px",
              xl: "20px",
            },
             fontWeight: "600",
           }}
        >
          <Typography  className="responsive_fontsize32">
             Frequently Asked Questions
          </Typography>
        </CustomDivider>
      </Box>
      {/* FAQ List Section */}
      <Grid container 
      justifyContent="center"
       sx={{ 
        // backgroundColor:"red",
        margin: {
      xs: "15px 5px 5px 5px",  // For extra-small screens (e.g., mobile)
      sm: "20px 5px 8px 5px",   // For small screens (e.g., tablets)
      md: "30px 5px 10px 5px",  // For medium screens (e.g., laptops)
      lg: "30px 5px 10px 5px",  // For large screens (e.g., desktops)
      xl: "30px 5px 10px 5px",  // For extra-large screens
    }, }}>
        <Grid item xs={12} md={8} sx={{ pr: { xs: 1, sm: 2, md: 3 } }}>
          {faqsData.map((faq, index) => (
            <Accordion key={index} sx={{ mb: {
              xs: 1,   // 8px for extra-small screens (mobile)
              sm: 1.5, // 12px for small screens (tablets)
              md: 2,   // 16px for medium screens (laptops)
              lg: 2,   // 16px for large screens (desktops)
              xl: 2    // 16px for extra-large screens
            }  }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography
                  // variant="subtitle1"
                  className="responsive_fontsize24"
                  sx={{ 
                    // fontWeight: "bold"
                   }}
                >
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography 
                // variant="body1"
                sx={{
                  fontWeight: "400"
                }}
                 className="responsive_fontsize20">
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
      </Box>
    </>
  );
};

export default FAQSection;

import React from "react";
import {
  Box,
  Typography,
} from "@mui/material";

function OrderDetails({content}) {
  return (
    <>
      <Box sx={{ 
        display: "flex",
        marginBottom: "15px" }}>
                <Typography
                  className="responsive_fontsize20"
                  sx={{ fontFamily: "Roboto Serif", minWidth: "150px" }}
                >
                  {content.title}
                </Typography>
                <Typography
                  className="responsive_fontsize18"
                  sx={{ fontFamily: "Roboto Serif", marginLeft: "8px" }}
                >
                  {content.value}
                </Typography>
      </Box>
    </>
  );
}

export default OrderDetails;

import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../layout/nav_bar/nav_bar_main";
import FooterBar from "../layout/footer_bar/footer_bar_main";
function RootLayout() {
  return (
    <>
      <NavBar />
      <Outlet />
      <FooterBar />
    </>
  );
}

export default RootLayout;

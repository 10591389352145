import React, { useState } from "react";
import { TextField, Button, DialogContent } from "@mui/material";

const ResetPasswordSection = ({ onResetPassword }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleReset = () => {
    if (newPassword === confirmPassword) {
      onResetPassword();
    } else {
      // Handle password mismatch error
      console.error("Passwords do not match");
    }
  };

  return (
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        label="New Password"
        type="password"
        fullWidth
        variant="standard"
        value={newPassword}
        onChange={handleNewPasswordChange}
      />
      <TextField
        margin="dense"
        label="Confirm Password"
        type="password"
        fullWidth
        variant="standard"
        value={confirmPassword}
        onChange={handleConfirmPasswordChange}
      />
      <Button onClick={handleReset} variant="contained" color="primary" sx={{ mt: 2 }}>
        Reset Password
      </Button>
    </DialogContent>
  );
};

export default ResetPasswordSection;

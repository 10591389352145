import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  FormGroup
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import HeaderSection from "./HeaderSection";
import GridLayout from "./GridLayout";
import MobileSlider from "./MobileSlider";
import { setSelectedCategory } from "../../../../redux/slices/menuSlice";

function FirstSection() {
  const dispatch = useDispatch();
  const joinData = useSelector((state) => state.menus.joinData);
  const selectedCategory = useSelector((state) => state.menus.selectedCategory);

  const [searchedItems, setSearchedItems] = useState([]);
  const [isMomoChecked, setIsMomoChecked] = useState(false);
  const [momoOptions, setMomoOptions] = useState({
    Steam: false,
    Fried: false,
    Jhol: false,
    C: false,
  });
 

  useEffect(() => {
    if (!selectedCategory) {
      dispatch(setSelectedCategory("menu"));
    }
  }, [dispatch, selectedCategory]);

  useEffect(() => {
    if (joinData[0]) {
      const filteredItems = (joinData[0] || []).filter(
        (item) => item.item_name === selectedCategory
      );

      if (selectedCategory === "Momo") {
        const uniqueSubcategories = [
          ...new Map(
            filteredItems.map((item) => [item.item_subcategory, item])
          ).values(),
        ];
        setSearchedItems(uniqueSubcategories);
      } else {
        setSearchedItems(filteredItems);
      }
    } else {
      setSearchedItems([]);
    }
  }, [selectedCategory, joinData]);

  const itemNames = [
    ...new Set((joinData[0] || []).map((item) => item.item_name)),
  ];

  const handleMomoCheckboxChange = (event) => {
    setIsMomoChecked(event.target.checked);
  };

  const handleMomoOptionChange = (option) => (event) => {
    setMomoOptions({
      ...momoOptions,
      [option]: event.target.checked,
    });
  };

  

  return (
    <Box sx={{ padding: 1, mb: 10 }}>
      <HeaderSection
        selectedCategory={searchedItems.length > 0 ? selectedCategory : "Menu"}
        setSelectedCategory={(category) => {
          setSearchedItems([]);
          dispatch(setSelectedCategory(category));
        }}
        categories={itemNames}
      />

      {selectedCategory === "Momo" && (
        <>
          <FormControlLabel
            control={
              <Checkbox
                checked={isMomoChecked}
                onChange={handleMomoCheckboxChange}
                color="primary"
              />
            }
            label="Select Type of Momo"
          />
          {isMomoChecked && (
            <FormGroup
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row", md: "row" },
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={momoOptions.Steam}
                    onChange={handleMomoOptionChange("Steam")}
                  />
                }
                label="Steam"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={momoOptions.Fried}
                    onChange={handleMomoOptionChange("Fried")}
                  />
                }
                label="Fried"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={momoOptions.Jhol}
                    onChange={handleMomoOptionChange("Jhol")}
                  />
                }
                label="Jhol"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={momoOptions.C}
                    onChange={handleMomoOptionChange("C")}
                  />
                }
                label="C"
              />
            </FormGroup>
          )}
        </>
      )}

      {searchedItems.length > 0 ? (
        <>
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <MobileSlider searchedItems={searchedItems} />
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <GridLayout searchedItems={searchedItems} />
          </Box>
          {/* Add-to-cart button */}
         
        </>
      ) : (
        <Typography
          variant="h6"
          sx={{ textAlign: "center", fontFamily: "roboto serif" }}
        >
          No items available in the selected category.
        </Typography>
      )}
    </Box>
  );
}

export default FirstSection;

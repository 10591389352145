import React, { useState} from "react";
import { useSelector } from "react-redux";
import { Typography, Box, Paper, Divider } from "@mui/material";
import MenuHeader from "./menuHeader";
import MenuItemList from "./menuItemListing";

function MakeMenuList({handleOpenDelete, handleMenuDelete, handleDeleteDialog, handleSaveMenuName}) {
  const selectedMenus = useSelector((state) => state.menus.selectedMenus);
  const currentMenu = useSelector((state) => state.menus.currentMenu);

  const [menuCardCounts, setMenuCardCounts] = useState({});
  // Update menuCardCounts when selectedMenus changes
  React.useEffect(() => {
    if (selectedMenus.length > 0) {
      const counts = selectedMenus.reduce((acc, menu, index) => {
        acc[index] = menu.selectedItems ? menu.selectedItems.length : 0;
        return acc;
      }, {});
      setMenuCardCounts(counts);
    }
  }, [selectedMenus]);

  console.log("selected menu and their items in selected items", selectedMenus.selectedItems, currentMenu.selectedItems);

  if (!currentMenu) return null;

  return (
    <Box
      sx={{
        position: "relative",
        marginTop: "20px",
        padding: selectedMenus.length > 0 ? "20px" : "0",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        backgroundColor: "#f9f3e1",
        mr: 10,
      }}
    >
      <Typography
        className="responsive_fontsize28"
        variant="h5"
        sx={{
          textAlign: "center",
          marginBottom: "16px",
          fontFamily: "roboto serif",
        }}
      >
        Your Menu
      </Typography>

      {currentMenu && (
        <Paper elevation={3} sx={{ padding: "16px", marginBottom: "16px" }}>
          <Box>
          <MenuHeader
            currentMenu={currentMenu}
            currentMenuIndex={selectedMenus.indexOf(currentMenu)}
            />

          <Divider sx={{ marginY: "16px" }} />
          <MenuItemList
            items = {currentMenu.selectedItems}
            handleSaveMenuName = {handleSaveMenuName}
            currentMenu={currentMenu}
            menuCardCounts={menuCardCounts}
            currentMenuIndex={selectedMenus.indexOf(currentMenu)}
            menuName={currentMenu}
            handleOpenDelete={handleOpenDelete}
            handleMenuDelete={handleMenuDelete}
            handleDeleteDialog={handleDeleteDialog}
            />
          </Box>
        </Paper>
      )}
    </Box>
  );
}

export default MakeMenuList;

// src/redux/slices/customerSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url='https://www.bbgrestaurant.com/bbgapi';
// Define the thunk for fetching customer profile
export const fetchCustomerProfile = createAsyncThunk(
  'customer/fetchCustomerProfile',
  async (customerId) => {
    const response = await axios.get(`${url}/api/customers/${customerId}`);
    return response.data; // Assuming the response data is the customer profile
  }
);

const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    customerProfile: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerProfile.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error on new request
      })
      .addCase(fetchCustomerProfile.fulfilled, (state, action) => {
        state.customerProfile = action.payload;
        state.loading = false;
      })
      .addCase(fetchCustomerProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Selectors
export const selectCustomerProfile = (state) => state.customer.customerProfile;
export const selectCustomerLoading = (state) => state.customer.loading;
export const selectCustomerError = (state) => state.customer.error; // New error selector

export default customerSlice.reducer;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  Container,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import backgroundImage from "../../../..//assets/logo.jpg";
import axios from "axios";
import ForgotPasswordDialog from "./forgotPasswordDialog";
import { toast } from 'react-toastify';
import { loginRequest, loginSuccess, loginFailure } from "src/redux/slices/authSlice";

const   url = 'https://www.bbgrestaurant.com/bbgapi';
            // 'https://bbgrestaurant.com/bbgapi'
          // ]
          

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    contact: "",
    password: "",
  });
  // console.log("Form Data:",formData);
  // const [error, setError] = useState(false);

  const [error] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  if (formData && typeof formData === 'object' && Object.keys(formData).length > 0) {
    // const entries = Object.entries(formData);
    // console.log(entries);
    // Optional: Handle entries if needed
  } else {
    console.error("formData is invalid or empty.");
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleForgotPasswordClick = () => {
    setOpenDialog(true);
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   try {
  //     const response = await axios.post("http://localhost:8001/bbgapi/customer/login", {
  //       customer_contact: formData.username,
  //       customer_password: formData.password,
  //     });

  //     const { data } = response.data;
  //     if (data && data.customer) {
  //       const { customer, token } = data;
  //       localStorage.setItem('authToken', token);
  //       localStorage.setItem('customerId', customer.cid);
  //       dispatch(loginSuccess());
  //       toast.success(`Login successful! Welcome to BBG ${customer.customer_name} `);
  //       navigate("/profile");
  //     } else {
  //       console.error("Customer data is missing in the response:", data);
  //       setError(true);
  //     }
  //   } catch (error) {
  //     if (error.response) {
  //       console.error("Login error:", error.response.data);
  //       setError(true);
  //     } else {
  //       console.error("Login error:", error.message);
  //       setError(true);
  //     }
  //   }
  
  // };
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if formData has keys and is valid before making the API request
  if (formData && typeof formData === 'object' && Object.keys(formData).length > 0) {
    // const entries = Object.entries(formData);
    // You can log the entries if needed
    // console.log(entries);
  } else {
    return toast.warn("Form data is invalid.");
  }

    if (!formData.contact || !formData.password) {
      return toast.warn("Both fields are required.");
    }
    dispatch(loginRequest());
    
    try {
      const response = await axios.post(`${url}/customer/login`, {
        customer_contact: formData.contact,
        customer_password: formData.password,
      });

      const { data } = response.data;
      if (data && data.customer) {
        dispatch(loginSuccess(data.customer));
        const { customer, token } = data;
        localStorage.setItem('authToken', token);
        localStorage.setItem('customerId', customer.cid);
        dispatch(loginSuccess(customer));
        toast.success(`Login successful! Welcome to BBG ${customer.customer_name} `);
        navigate("/profile");
      } else {
        dispatch(loginFailure("Invalid credentials"));
        toast.error("Invalid credentials.");
      }
    } catch (error) {
      // Handle specific errors
      if (error.response) {
        const message = error.response.data.message;
        if (message === 'Incorrect contact number.') {
          toast.error("Wrong contact number.");
        } else if (message === 'Incorrect password') {
          toast.error("Wrong password.");
        } else {
          toast.error("Login failed. Please try again.");
        }
      } else {
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  const handleSignUpClick = () => {
    navigate("/signup");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container 
    component="main"
    maxWidth= "xl"
     >
      <CssBaseline />
      <Box sx={{
          marginTop: {xs:"10rem", md:"2.5rem"}
          }}>
        <Grid container sx={{
          marginTop:"2.5rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap:"2.5rem",
          }}>
          <Grid
             item
             xs={false}
             sm={false}
             md={5}
             lg={5}
             sx={{
               backgroundImage: `url(${backgroundImage})`,
               backgroundRepeat: "no-repeat",
               backgroundSize: "contain",
               backgroundPosition: "center",
               height: "100vh",
               display: { xs: "none", md: "block" } // Hide on small screens
             }}
          />
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            component={Paper}
            elevation={6}
            square
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              // height: { xs: "60vh", sm: "68vh" },
            }}
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                className="responsive_fontsize48"
                color="green"
              >
                Sign In
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: { xs: 2.7, sm: 3.5 } }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="contact"
                  name="contact"
                  label="Contact Number"
                  autoComplete="username"
                  autoFocus
                  value={formData.contact || ""}
                  onChange={handleInputChange}
                  error={error}
                  helperText={error ? "Incorrect contact" : ""}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  value={formData.password}
                  onChange={handleInputChange}
                  error={error}
                  helperText={error ? "Incorrect password" : ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          onClick={handleClickShowPassword}
                          aria-label="toggle password visibility"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className="responsive_fontsize18"
                  sx={{ mt: 1, mb: 3.2 }}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link
                      href="#"
                      variant="body2"
                      color="#006400"
                      className="responsive_fontsize18"
                      onClick={handleForgotPasswordClick}
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      href="#"
                      variant="body2"
                      color="primary"
                      className="responsive_fontsize20"
                      onClick={handleSignUpClick}
                    >
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Forgot Password Dialog */}
      <ForgotPasswordDialog open={openDialog} onClose={() => setOpenDialog(false)} />
    </Container>
  );
};

export default LoginForm;

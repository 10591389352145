import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { motion } from "framer-motion"; // Import useAnimation
import hygenicIcon from "../../assets/hygenicIcon.png";
import quantityIcon from "../../assets/quantityIcon.png";
import qualityIcon from "../../assets/qualityIcon.png";
import easyPaymentIcon from "../../assets/easyPaymentIcon.png";
import affordableIcon from "../../assets/affordableIcon.png";
import onTimeIcon from "../../assets/onTimeIcon.png";

const fadeInAnimationsVariants = {
  initial: {
    opacity: 0,
    y: 100,
  },
  animate: (index) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.05 * index,
    },
  }),
};
function PromiseSection() {
  return (
    <Box sx={{ mb: "2rem" }}>
      <Box>
        <Typography
        className="responsive_fontsize32"
          sx={{
            fontFamily: "Roboto Serif",
            // fontSize: "2rem",
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          We Promise
        </Typography>
      </Box>
      <Box sx={{  mt: {
      xs: '2rem',  // for extra-small screens (mobile)
      sm: '2.5rem',  // for small screens (tablets)
      md: '3rem',  // for medium screens (small laptops)
      lg: '4rem',  // for large screens (desktops)
    },}}>
        <Grid
          container
          spacing={10}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item xs={6} sm={4} md={2}>
            <motion.div
              variants={fadeInAnimationsVariants}
              initial="initial"
              whileInView="animate"
              viewport={{
                once: true,
              }}
              custom={1}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={qualityIcon} alt="Quality Icon" />
                <Typography
                  sx={{
                    color: "#EAA017",
                    fontSize: "1.125rem",
                    mt: {
                      xs: '0.1rem',  // for extra-small screens (mobile)
                      sm: '0.2rem',  // for small screens (tablets)
                      md: '0.3rem',  // for medium screens (small laptops)
                      lg: '0.5rem',  // for large screens (desktops)
                    },
                  }}
                >
                  Quality
                </Typography>
              </Box>
            </motion.div>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <motion.div
              variants={fadeInAnimationsVariants}
              initial="initial"
              whileInView="animate"
              viewport={{
                once: true,
              }}
              custom={2}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={hygenicIcon} alt="Hygenic Icon" />
                <Typography
                  sx={{
                    color: "#EAA017",
                    fontSize: "1.125rem",
                    mt: {
                      xs: '0.1rem',  // for extra-small screens (mobile)
                      sm: '0.2rem',  // for small screens (tablets)
                      md: '0.3rem',  // for medium screens (small laptops)
                      lg: '0.5rem',  // for large screens (desktops)
                    },
                  }}
                >
                  Hygienic
                </Typography>
              </Box>
            </motion.div>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <motion.div
              variants={fadeInAnimationsVariants}
              initial="initial"
              whileInView="animate"
              viewport={{
                once: true,
              }}
              custom={3}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={quantityIcon} alt="Quantity Icon" />
                <Typography
                  sx={{
                    color: "#EAA017",
                    fontSize: "1.125rem",
                    mt: {
                      xs: '0.1rem',  // for extra-small screens (mobile)
                      sm: '0.2rem',  // for small screens (tablets)
                      md: '0.3rem',  // for medium screens (small laptops)
                      lg: '0.5rem',  // for large screens (desktops)
                    },
                  }}
                >
                  Quantity
                </Typography>
              </Box>
            </motion.div>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <motion.div
              variants={fadeInAnimationsVariants}
              initial="initial"
              whileInView="animate"
              viewport={{
                once: true,
              }}
              custom={4}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={affordableIcon} alt="Affordable Icon" />
                <Typography
                  sx={{
                    color: "#EAA017",
                    fontSize: "1.125rem",
                    mt: {
                      xs: '0.1rem',  // for extra-small screens (mobile)
                      sm: '0.2rem',  // for small screens (tablets)
                      md: '0.3rem',  // for medium screens (small laptops)
                      lg: '0.5rem',  // for large screens (desktops)
                    },
                  }}
                >
                  Affordable
                </Typography>
              </Box>
            </motion.div>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <motion.div
              variants={fadeInAnimationsVariants}
              initial="initial"
              whileInView="animate"
              viewport={{
                once: true,
              }}
              custom={5}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={onTimeIcon} alt="On Time Icon" />
                <Typography
                  sx={{
                    color: "#EAA017",
                    fontSize: "1.125rem",
                    mt: {
                      xs: '0.1rem',  // for extra-small screens (mobile)
                      sm: '0.2rem',  // for small screens (tablets)
                      md: '0.3rem',  // for medium screens (small laptops)
                      lg: '0.5rem',  // for large screens (desktops)
                    },
                  }}
                >
                  On Time
                </Typography>
              </Box>
            </motion.div>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <motion.div
              variants={fadeInAnimationsVariants}
              initial="initial"
              whileInView="animate"
              viewport={{
                once: true,
              }}
              custom={6}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={easyPaymentIcon} alt="Easy Payment Icon" />
                <Typography
                  sx={{
                    color: "#EAA017",
                    fontSize: "1.125rem",
                    mt: {
                      xs: '0.1rem',  // for extra-small screens (mobile)
                      sm: '0.2rem',  // for small screens (tablets)
                      md: '0.3rem',  // for medium screens (small laptops)
                      lg: '0.5rem',  // for large screens (desktops)
                    },
                  }}
                >
                  Easy Payment
                </Typography>
              </Box>
            </motion.div>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default PromiseSection;

import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, Box, TextField, Autocomplete } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import "./search_bar.css";
import foodItemList from "../offerSection/dataItems";

function SearchBar() {
  const [isGrowing, setIsGrowing] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();

  const animationVariants = {
    grow: { scale: 1.2, transition: { duration: 1 } },
    shrink: { scale: 1, transition: { duration: 1 } },
  };

  const toggleOpen = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
    if (!isOpen) {
      setSearchQuery(""); // Clear search query when opening
      setSelectedItem(null); // Clear selected item when opening
    }
  };

  const textVariants = {
    closed: { width: "0rem", opacity: 0 },
    open: { width: "15rem", opacity: 1 },
  };

  const toggleAnimation = () => {
    setIsGrowing((prevIsGrowing) => !prevIsGrowing);
  };

  useEffect(() => {
    const intervalId = setInterval(toggleAnimation, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const keywords = extractKeywords(searchQuery);

    const filtered = foodItemList.filter((item) =>
      keywords.every(
        (keyword) =>
          (item.category && item.category.toLowerCase().includes(keyword)) ||
          (item.subcategory &&
            item.subcategory.some((sub) =>
              sub.toLowerCase().includes(keyword)
            )) ||
          (item.detail && item.detail.toLowerCase().includes(keyword))
      )
    );

    setFilteredItems(filtered);
  }, [searchQuery]);

  const extractKeywords = (query) => {
    // Normalize query by converting to lowercase
    const normalizedQuery = query.toLowerCase().trim();

    // Define common stop words or phrases to ignore
    const ignoredWords = new Set([
      "i",
      "want",
      "where",
      "can",
      "find",
      "like",
      "how",
      "much",
      "for",
      "which",
      "food",
      "is",
      "best",
      "sweet",
      "need",
    ]);

    // Clean the query by removing ignored words and splitting into keywords
    const cleanedQuery = normalizedQuery
      .split(/\s+/)
      .filter((word) => !ignoredWords.has(word))
      .join(" ");
    const keywords = cleanedQuery.split(/\s+/).filter((keyword) => keyword);

    return keywords;
  };

  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      return; // Prevent searching with empty query
    }

    const keywords = extractKeywords(searchQuery);

    const exactMatch = foodItemList.find((item) =>
      keywords.every(
        (keyword) =>
          (item.category && item.category.toLowerCase().includes(keyword)) ||
          (item.subcategory &&
            item.subcategory.some((sub) =>
              sub.toLowerCase().includes(keyword)
            )) ||
          (item.detail && item.detail.toLowerCase().includes(keyword))
      )
    );

    if (exactMatch) {
      navigate("/menu", { state: { selectedItem: exactMatch } });
    } else if (selectedItem) {
      navigate("/menu", { state: { selectedItem } });
    } else if (filteredItems.length > 0) {
      navigate("/menu", { state: { selectedItem: filteredItems[0] } });
    } else {
      // Handle case when no matches are found
      // Navigate to the menu page with no selected item state
      navigate("/menu");
      console.log("No matching item found.");
    }
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "50%",
        left: "20px",
        zIndex: "100",
        transform: "translate(0, 50%)",
        // backgroundColor: "yellow"
      }}
    >
      <Box sx={{ display: "flex" }}>
        {isOpen ? (
          <IconButton onClick={toggleOpen}>
            <Clear />
          </IconButton>
        ) : (
          <motion.div
            className="search-icon"
            animate={isGrowing ? "grow" : "shrink"}
            variants={animationVariants}
            onClick={toggleOpen}
          >
            <IconButton>
              <SearchIcon sx={{ color: "black" }} />
            </IconButton>
          </motion.div>
        )}
        <motion.div
          className="text-field-container"
          initial="closed"
          animate={isOpen ? "open" : "closed"}
          variants={textVariants}
        >
          {isOpen && (
            <Autocomplete
              freeSolo
              options={filteredItems.reduce((acc, item) => {
                if (item.category) {
                  acc.push({
                    label: item.category,
                    id: item.id,
                    type: "category",
                  });
                }
                if (item.subcategory) {
                  acc.push(
                    ...item.subcategory.map((sub) => ({
                      label: sub,
                      id: item.id,
                      type: "subcategory",
                    }))
                  );
                }
                if (item.detail) {
                  acc.push({ label: item.detail, id: item.id, type: "detail" });
                }
                return acc;
              }, [])}
              getOptionLabel={(option) => option.label || ""}
              inputValue={searchQuery}
              onInputChange={(event, newInputValue) =>
                setSearchQuery(newInputValue)
              }
              onChange={(event, value) => {
                setSelectedItem(
                  value ? { id: value.id, type: value.type } : null
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Search"
                  size="small"
                  onFocus={() => setIsFocus(true)}
                  onBlur={() => setIsFocus(false)}
                  autoComplete="off"
                  sx={{ width: "100%" }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <SearchIcon
                        sx={{
                          cursor: "pointer",
                          transform: "scale(1.2)",
                          color: isFocus ? "primary.main" : "grey",
                        }}
                        onClick={handleSearch}
                      />
                    ),
                    style: {
                      borderRadius: "1rem",
                    },
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              )}
            />
          )}
        </motion.div>
      </Box>
    </Box>
  );
}

export default SearchBar;

import React from "react";
import PropTypes from 'prop-types';
import { Box, IconButton, Typography } from "@mui/material";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import AddIcon from "@mui/icons-material/Add";
import SearchBar from "src/components/search_bar/search_bar_main";
import SocialMediaBar from "src/components/social_media_bar/social_media_bar_main";
import { useDispatch, useSelector } from "react-redux";
import {
  setMenuName,
  setDeleteMenuName,
  closeConfirmDeleteDialog,
  closeMenuNameDialog,
  openMenuNameDialog,
  
} from "src/redux/slices/menuSlice";
import MenuDialogs from "../FoodCard/MenuCardDialog";

const Header = ({handleOpenMenuNameDialog, handleInputChange, handleSaveMenuName}) => {
  const dispatch = useDispatch();
  const {menuName, confirmDeleteDialog, deleteMenuName, openMenuDialog, popoverID} = useSelector((state) => state.menus);

  const handleConfirmDelete = () => {
    dispatch(setDeleteMenuName(deleteMenuName));
    handleCloseConfirmDeleteDialog();
  };

  const handleCloseConfirmDeleteDialog = () => {
    dispatch(closeConfirmDeleteDialog());
  };

  return (
    <>
      <SearchBar />
      <SocialMediaBar />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          m: {
            xs: "5rem 0 0.5rem 0 ",
            sm: "5rem 0 0.5rem 0",
            md: "5rem 0 0.5rem 0 ",
            lg: "5rem 0 1rem 0",
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", md: "column" },
            alignItems: "center",
            gap: { xs: 2, md: 0 },
            mt: 5,
          }}
        >
          <Box sx={{ display: "flex", mb: { sm: 0, md: 2 }, ml: 10 }}>
            <IconButton
              sx={{
                "&:hover": {
                  backgroundColor: "white",
                },
              }}
            >
              <FastfoodIcon
                className="responsive_fontsize32"
                sx={{ color: "#EAB42A" }}
              />
            </IconButton>

            <Typography
              className="responsive_fontsize32"
              sx={{ color: "green" }}
            >
              Make Your Menu
            </Typography>
            <IconButton
               onClick={(e) => {handleOpenMenuNameDialog(e, null);}}
              sx={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                ml: 2,
                "&:hover": {
                  backgroundColor: "white",
                },
              }}
            >
              <AddIcon sx={{ color: "green" }} />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {openMenuDialog && popoverID === `header` && (
        <MenuDialogs
          makeMenuDialog = {() => dispatch(openMenuNameDialog())}
          handleMakeClose={() => dispatch(closeMenuNameDialog())}
          menuName={menuName}
          handleChange={handleInputChange}
          setMenuName={(value) => dispatch(setMenuName(value))}
          confirmDeleteDialog={confirmDeleteDialog}
          setConfirmDeleteDialog={handleCloseConfirmDeleteDialog}
          deleteMenuName={deleteMenuName}
          confirmDelete={handleConfirmDelete}
          handleSave={handleSaveMenuName}
        />
      )}
    </>
  );
};

Header.propTypes = {
  openPopover: PropTypes.bool.isRequired,
  handleClosePopover: PropTypes.func.isRequired,
  handleOpenMenuNameDialog: PropTypes.func.isRequired,
  handleMenuSelect: PropTypes.func.isRequired,
  handleDeleteMenu: PropTypes.func.isRequired,
  selectedMenus: PropTypes.arrayOf(
    PropTypes.shape({
      menuName: PropTypes.string.isRequired
    })
  ).isRequired,
};

export default Header;
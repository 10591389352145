// src/data/faqsData.js

const faqsData = [
    {
      question: "What are your opening hours?",
      answer: "We are open from 11:00 AM to 10:00 PM every day of the week."
    },
    {
      question: "Do you offer delivery services?",
      answer: "Yes, we offer delivery within a 5-mile radius. Delivery charges may apply."
    },
    {
      question: "Is there parking available?",
      answer: "Yes, we have a dedicated parking lot for customers."
    },
    {
      question: "Do you accommodate dietary restrictions?",
      answer: "Yes, we offer a variety of dishes suitable for vegetarian, vegan, and gluten-free diets."
    },
    {
      question: "Can I make reservations?",
      answer: "Yes, you can make reservations by calling us at (123) 456-7890."
    }
  ];
  
  export default faqsData;
  
import React, { useState, useEffect } from "react";
import { Button, Box, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import FoodCard from "./foodcard";
import { Buffer } from "buffer"; // Import Buffer to handle image data

function FoodCardList() {
  const isExtraSmall = useMediaQuery("(max-width: 600px)");
  const isSmall = useMediaQuery("(max-width: 1080px)");
  const isMedium = useMediaQuery("(max-width: 1600px)");

  const [visibleCount, setVisibleCount] = useState(4);
  const [showMore, setShowMore] = useState(false);

  // Get the joinData from Redux store
  const joinData = useSelector((state) => state.menus.joinData);
  console.log("offer section ",joinData);
  const getCurrentPrice = (priceData) => {
    // Get current hour
    const hour = new Date().getHours();

    // Determine if it's day or night (daytime from 6 AM to 6 PM)
    const isDay = hour >= 6 && hour < 18;

    // Parse item_price
    let prices = [];
    try {
      if (priceData) {
        prices = JSON.parse(priceData);
      }
    } catch (e) {
      console.error("Error parsing item_price:", e);
    }

    // Return the appropriate price based on time of day
    if (prices.length > 1) {
      return isDay ? prices[0] : prices[1]; // First price for day, second for night
    } else if (prices.length === 1) {
      return prices[0]; // Return the single price if only one is provided
    } else {
      return "Price not available";
    }
  };

  useEffect(() => {
    if (isExtraSmall) {
      setVisibleCount(1);
    } else if (isSmall) {
      setVisibleCount(2);
    } else if (isMedium) {
      setVisibleCount(3);
    } else {
      setVisibleCount(4);
    }
  }, [isExtraSmall, isSmall, isMedium]);

  const handleShowMore = () => {
    setVisibleCount(flattenedFoodItems.length);
    setShowMore(true);
  };

  const handleShowLess = () => {
    if (isExtraSmall) {
      setVisibleCount(1);
    } else if (isSmall) {
      setVisibleCount(2);
    } else if (isMedium) {
      setVisibleCount(3);
    } else {
      setVisibleCount(4);
    }
    setShowMore(false);
  };

  // Flatten the joinData to map it correctly for the FoodCard component
  const flattenedFoodItems = (joinData[0] || []).map((foodItem, index) => {
    // Handle image conversion (buffer to base64)
    const buffer = foodItem.item_image?.data || [];
    const base64Image = `data:${foodItem.mimeType};base64,${Buffer.from(buffer).toString("base64")}`;

    // Calculate current price here for each item
    const currentPrice = getCurrentPrice(foodItem.item_price);

    return {
      ...foodItem,
      subcategoryId: `${foodItem.item_id}-${index}`, // Unique key for each food item
      image: base64Image, // Add the image to the foodItem object
      currentPrice, // Include currentPrice in the object
    };
  });

  return (
    <Box sx={{ padding: "16px" }}>
      <Box
        sx={{
          padding: "20px 0 20px 0",
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: {
            xs: "1rem",
            sm: "2.8rem",
            md: "3rem",
            lg: "3.8rem",
            xl: "4.5rem",
          },
          justifyContent: "center",
          alignItems: "center",
          "@media (max-width: 1600px)": {
            gridTemplateColumns: "repeat(3, 1fr)",
          },
          "@media (max-width: 1080px)": {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
          "@media (max-width: 600px)": {
            gridTemplateColumns: "repeat(1, 1fr)",
          },
        }}
      >
        {flattenedFoodItems.slice(0, visibleCount).map((foodItem) => (
          <FoodCard
            key={foodItem.subcategoryId}
            foodItem={foodItem}
            subcategory={`${foodItem.item_subcategory} ${foodItem.item_name}`} 
            image={foodItem.image} 
            price={foodItem.currentPrice} // Pass currentPrice to FoodCard
            detail={foodItem.item_description}
            
          />
        ))}
      </Box>
      <Box sx={{ textAlign: "center", marginTop: "16px" }}>
        {!showMore ? (
          <Button variant="contained" onClick={handleShowMore}>
            Show More
          </Button>
        ) : (
          <Button variant="contained" onClick={handleShowLess}>
            Show Less
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default FoodCardList;

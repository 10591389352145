import React, { useState } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  Container,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import backgroundImage from "../../../assets/logo.jpg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { signupRequest, signupSuccess, signupFailure } from "src/redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const  url ='https://www.bbgrestaurant.com/bbgapi'

const SignUpForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: "",
    customer_contact: "",
    password: "",
    confirmPassword: "",
  });


  const [error, setError] = useState({
    contact: "",
    password: "",
    confirmPassword: ""
  });

  // const [error] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Validate contact number to only allow digits
    if (name === "customer_contact") {
      if (!/^\d*$/.test(value)) {
        setError({ ...error, contact: "Only numbers are allowed in the contact number." });
        return;
      }
      // Limit to a total of 10 digits
      if (value.length > 10) {
        return;
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    // Reset specific error when user starts typing
    setError({
      ...error,
      [event.target.name]: "",
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validateContact = (contact) => {
    if (contact.length !== 10) {
      toast.error("Contact number must be 10 digits long.");
      return false;
    }
    
    // if (!/^(98|97)/.test(contact)) {
    //   toast.error("Contact number must start with 98 or 97.");
    //   return false;
    // }
  
    return true;
  };
  
  const validatePassword = (password) => {
    const minLength = 6;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasDigits = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*]/.test(password);
  
    if (password.length < minLength) {
      toast.error("Password must be at least 6 characters long.");
      return false;
    }
    if (!hasUpperCase) {
      toast.error("Password must contain at least one uppercase letter.");
      return false;
    }
    if (!hasLowerCase) {
      toast.error("Password must contain at least one lowercase letter.");
      return false;
    }
    if (!hasDigits) {
      toast.error("Password must contain at least one digit.");
      return false;
    }
    if (!hasSpecialChar) {
      toast.error("Password must contain at least one special character.");
      return false;
    }  
    return true;
  };

  const handlePasswordMatch = () => {
    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match.");
      return false;
    }
    return true;
  };
//    // Function to check if contact number already exists
//    const checkContactExists = async (contact) => {
//     try {
//         const response = await fetch(`http://localhost:8001/bbgapi/users/check?customer_contact=${contact}`, {
//             method: 'GET',
//             credentials: 'include', // Include cookies with the request if needed
//             headers: {
//                 'Content-Type': 'application/json',
//                 // 'Authorization': `Bearer ${yourToken}`, // Only if needed
//             },
//         });
//         if (response.ok) {
//             const data = await response.json();
//             return data.exists; // Assuming your API responds with this structure
//         } else {
//             throw new Error("Network response was not ok");
//         }
//     } catch (error) {
//         console.error("Error checking contact existence:", error);
//         return false;
//     }
// };

const checkContactExists = async (contact) => {
  try {
    console.log(localStorage.getItem('authToken'))
    const token = localStorage.getItem('authToken')
    const response = await axios.get(`${url}/customer`, {
      params: { customer_contact: contact },
      headers: {
        Authorization: `Bearer ${token}`, // Replace with actual token
      },
    });
    console.log("token", token)
    return response.data.exists;
  } catch (error) {
    console.error("Error checking contact existence:", error);
    return false;
  }
};


  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate phone contact
    if (!validateContact(formData.customer_contact)) {
      setError(prev => ({ ...prev, contact: "Invalid contact." }));
      return;
    }

    // Check if contact already exists
    const exists = await checkContactExists(formData.customer_contact);
    if (exists) {
      setError(prev => ({ ...prev, contact: "This contact number is already registered." }));
    toast.error("This contact number is already registered.");
    return;
    }
  //  // Check if contact already exists
  //  const exists = await checkContactExists(formData.customer_contact);
  //  if (exists) {
  //    toast.error("This contact number is already registered.");
  //    return;
  //  }

    // Validate password length
    if (!validatePassword(formData.password)) {
      setError(prev => ({ ...prev, password: "Invalid password." }));
      return;
    }

    // Check if passwords match
    if (!handlePasswordMatch()) {
      setError(prev => ({ ...prev, confirmPassword: "Passwords do not match." }));
      return;
    }

    dispatch(signupRequest());
    try {
      // const response = 
      await axios.post(`${url}/customer`, {
        customer_name: formData.name,
        customer_contact: formData.customer_contact,
        customer_password: formData.password,
      });

      dispatch(signupSuccess());
      toast.success('Sign up successfully.');
      navigate("/login");
    } catch (error) {
      dispatch(signupFailure(error.response?.data?.message || error.message));
      toast.error(error.response?.data?.message || "Sign up failed.");
    }
  };

  const handleSignUpClick = () => {
    navigate("/login");
  };

  return (
    <Container 
    component="main" 
    maxWidth="xl">
    <CssBaseline />
    <Box sx={{
          marginTop: {xs:"10rem", md:"2.5rem"}
          }}>
        <Grid  container sx={{
          marginTop:"2.5rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap:"2.5rem",
          }}>
          <Grid
            item
            xs={false}
            sm={false}
            md={5}
            lg={5}
            sx={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
              height: "100vh",
              display: { xs: "none", md: "block" } // Hide on small screens
            }}
          />
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            component={Paper}
            elevation={6}
            square
            sx={{
              marginTop: {
                xs: "0rem",  // Smallest screen (extra small)
                sm: "1rem",  // Small screen (phones)
                md: "3.5rem",  // Medium screen (tablets)
                lg: "4.5rem",  // Large screen (laptops)
                xl: "5rem"  // Extra-large screen (desktops)
              },
              // backgroundColor:"red",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              // height: { xs: "60vh", sm: "68vh" },
            }}
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                className="responsive_fontsize48"
                color="green"
              >
                Sign Up
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  value={formData.name}
                  onChange={handleInputChange}
                  error={!!error.name}
                  helperText={error.name}
                />

                <TextField
                  variant="outlined"
                  fullWidth
                  label="Phone Number"
                  name="customer_contact"
                  value={formData.customer_contact}
                  onChange={handleInputChange}
                  required
                  margin="normal"
                  error={!!error.contact}
                  helperText={error.contact}
                  inputProps={{
                    maxLength: 10, // Allow a maximum of 10 digits
                    pattern: "^(98|97)[0-9]{8}$", // Regex to match 10 digits starting with 98 or 97
                    title: "Must start with 98 or 97 and followed by 8 digits.",
                  }}

                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="new-password"
                  value={formData.password}
                  onChange={handleInputChange}
                  error={!!error.password}
                  helperText={error.password || ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          onClick={handleClickShowPassword}
                          aria-label="toggle password visibility"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type={showPassword ? "text" : "password"}
                  id="confirmPassword"
                  autoComplete="new-password"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  error={!!error.confirmPassword}
                  helperText={error.confirmPassword || ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          onClick={handleClickShowPassword}
                          aria-label="toggle password visibility"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign Up
                </Button>
                <Grid container>
                  <Grid item xs>
                    {/* <Link href="#" variant="body2">
                      Forgot password?
                    </Link> */}
                  </Grid>
                  <Grid item>
                    <Link
                      href="#"
                      variant="body2"
                      color="primary"
                      onClick={handleSignUpClick}
                    >
                      {"Already have an account"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default SignUpForm;
import React from "react";
import { Card, CardContent, Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { addItem } from "src/redux/slices/cartSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const bufferToBase64 = (buffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

function FoodCard({ foodItem, subcategory, price, detail }) { // No need to calculate price
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const base64Image = foodItem.item_image
    ? `data:image/png;base64,${bufferToBase64(foodItem.item_image.data)}`
    : "/path/to/default/image.jpg";


  const handleAddToCart = () => {
    const concatenatedSubcategory = `${foodItem.item_subcategory} ${foodItem.item_name}`.trim();

    const itemPrice = parseFloat(price); // Using the passed prop price
    if (isNaN(itemPrice)) {
      toast.error("Invalid price. Please try again.");
      return; // Exit if the price is invalid
    }

    const itemToAdd = {
      id: foodItem.item_id,
      subcategory: concatenatedSubcategory,
      price: itemPrice,
      quantity: 1,
      image: base64Image,
      detail: foodItem.item_description,
    };

    dispatch(addItem(itemToAdd));
    toast.success(`${concatenatedSubcategory} added to cart!`);
  };

  const handleDesClick = () => {
    navigate("/description", { state: {
      foodItem: {
        ...foodItem,
        image: base64Image, // Pass the base64 image string
      },
      subcategory,
    }, });
  };

  return (
    <Card
      sx={{
        width: {
          xs: "330px",
          sm: "260px",
          md: "300px",
          lg: "320px",
          xl: "330px",
        },
        height: {
          xs: "350px",
          sm: "320px",
          md: "330px",
          lg: "340px",
          xl: "350px",
        },
        position: "relative",
        backgroundColor: "#261d1d",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <CardContent sx={{ padding: 0 }}>
        <img
          onClick={handleDesClick}
          src={foodItem.image}
          alt="Product"
          style={{
            width: "100%",
            height: "230px",
            objectFit: "cover",
          }}
        />
        <Box sx={{ padding: "5px 12px 0px 12px" }}>
          <Typography
            className="responsive_fontsize20"
            variant="subtitle1"
            sx={{
              my: 0,
              color: "#ffffff",
              fontWeight: "semi-bold",
            }}
          >
            {subcategory}
          </Typography>
          <Typography
            className="responsive_fontsize12"
            sx={{
              color: "#ffffff",
              fontWeight: "300",
            }}
          >
            {detail}
          </Typography>
        </Box>
        <Typography
          className="responsive_fontsize20"
          sx={{
            padding: "4px",
            position: "absolute",
            bottom: "8px",
            left: "8px",
            textAlign: "center",
            color: "#EAA017",
          }}
        >
          Rs {price}
        </Typography>
        <Button
          className="responsive_fontsize16"
          variant="contained"
          onClick={handleAddToCart}
          sx={{
            paddingTop: "1px",
            paddingBottom: "1px",
            position: "absolute",
            bottom: "14px",
            right: "8px",
            textTransform: "capitalize",
            borderRadius: "5px",
            color: "#000000",
            height: "1.5rem",
            backgroundColor: "#0ff34f",
            "&:hover": {
              backgroundColor: "#8e5a00",
            },
          }}
        >
          Add to Cart
        </Button>
      </CardContent>
    </Card>
  );
}

export default FoodCard;

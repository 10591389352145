import React, {useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExtraFoodSlider from "./extraFood/sliderFood";

function SpecialOffersDialog({ open, onClose }) {
  useEffect(() => {
    if (open) {
      console.log("Dialog opened");
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography
          className="responsive_fontsize32"
          sx={{
            textAlign: "center",
            color: "#045307",
          }}
        >
          Special Offers
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: { xs: "10px", sm: "20px" } }}>
        <ExtraFoodSlider />
      </DialogContent>
    </Dialog>
  );
}

export default SpecialOffersDialog;
